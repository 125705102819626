import React, { useEffect, useState } from 'react'
import 'moment-timezone'
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { uiActions } from 'stores/ui/ui-slice'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { getAllNotifications } from 'stores/notification/notification-actions'
import AddBalance from 'app/dashboard/components/AddBalance'

interface TopBarProps {
  onMobileNavOpen: () => void
}

const TopBar: React.FC<TopBarProps> = ({ onMobileNavOpen, ...rest }) => {
  const dispatch = useDispatch()
  // const theme = useTheme()
  // const matches = useMediaQuery(theme.breakpoints.up('sm'))
  // const { isDarkMode } = useSelector((state: RootState) => state.ui)
  // const [isOpen, setIsOpen] = useState<boolean>(false)
  // const [isShortcutsOpen, setIsShortCutsOpen] = useState<boolean>(false)
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const [shortcutsAnchorEl, setShortCutsAnchorEl] = useState<null | HTMLElement>(null)
  const [pageSize, setPageSize] = useState<number>(10)

  // const getNotifications = useSelector((state: RootState) => state.notification.notification)

  // const getNotificationsCount = useSelector(
  //   (state: RootState) => state.notification.notificationCount
  // )

  useEffect(() => {
    // @ts-ignore
    dispatch(uiActions.setDarkMode(!!JSON.parse(localStorage.getItem('isDarkMode'))))
  }, [dispatch])

  useEffect(() => {
    dispatch(getAllNotifications(1, pageSize))
  }, [dispatch, pageSize])

  // const toggleDarkMode = (value: any) => {
  //   localStorage.setItem('isDarkMode', value)
  //   dispatch(uiActions.setDarkMode(value))
  // }

  // const handleLogout = () => {
  //   dispatch(logout())
  // }

  // const toggleShortcuts = (event: React.MouseEvent<HTMLElement>) => {
  //   setShortCutsAnchorEl(event.currentTarget)
  //   setIsShortCutsOpen(!isShortcutsOpen)
  // }

  // const toggleNotifications = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(event.currentTarget)
  //   setIsOpen(!isOpen)
  // }

  // const handleClearNotification = (id: string) => {
  //   if (id) {
  //     dispatch(clearNotification(id))
  //     dispatch(notificationActions.removeNotification(id))
  //   }
  // }

  // const handleClearAllNotifications = () => {
  //   dispatch(clearAllNotifications())
  //   dispatch(notificationActions.removeAllNotifications())
  // }

  const { userInfo } = useSelector((state: RootState) => state.user)
  const location = useLocation()

  const getTitle = () => {
    switch (location.pathname) {
      case '/app/users':
        return 'User Management'
      case '/app/transactions':
        return 'Transaction Records'
      case '/app/games':
        return 'Game Records'
      case '/app/jp-records':
        return 'JP Records'
      case '/app/admin-structure':
        return 'Admin Structure'
      case '/app/reports':
        return 'Reports'
      case '/app/jp-settings':
        return 'JP Settings'
      case '/app/manage/contact-message':
        return 'Contact Messages'
      case '/app/burnt-down-records':
        return 'Burnt Down Records'
      case '/app/manage/games':
        return 'Manage Games'
      case '/app/manage/category':
        return 'Manage Category'
      case '/app/manage/distributors':
        return 'Manage Distributors'
      case '/app/manage/udid':
        return 'Manage UDID'
      case '/app/dashboard':
        return 'Dashboard'
      default:
        return null
    }
  }
  return (
    <AppBar
      elevation={0}
      {...rest}
      sx={{
        // width: { lg: 'calc(100% - 256px)' },
        bgcolor: 'primary.main',
        position: 'unset',
        height: 50,
        justifyContent: 'center',
      }}
    >
      <Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <Typography variant="h5" sx={{ fontSize: 18 }}>
            Break The Bank {getTitle() && <span style={{ fontSize: 15 }}>/ {getTitle()}</span>}
          </Typography>
          {/* <Hidden mdDown> */}

          {/* </Hidden> */}
          <Box sx={{ display: 'flex' }}>
            <AddBalance />
            <Button
              variant="contained"
              sx={{
                backgroundColor: 'secondary.light',
                textTransform: 'none',
                padding: '6px 12px',
                borderRadius: '16px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: 'secondary.light',
                },
              }}
            >
              Welcome {userInfo?.nickname} ({userInfo?.role})
            </Button>
            {/* <Tooltip title="Shortcuts" arrow>
              <IconButton
                size="large"
                onClick={toggleShortcuts}
                sx={{ color: 'white' }}
                aria-label="shortcuts"
                component="label"
              >
                <PostAddOutlined />
              </IconButton>
            </Tooltip> */}

            {/* <Tooltip title={isDarkMode ? 'Light Mode' : 'Dark Mode'} arrow>
              <IconButton
                size="large"
                sx={{ color: 'white' }}
                aria-label="upload picture"
                component="label"
                onClick={() => toggleDarkMode(!isDarkMode)}
              >
                {isDarkMode ? <LightModeRoundedIcon /> : <DarkModeOutlinedIcon />}
              </IconButton>
            </Tooltip> */}
            {/* 
            <Tooltip title="Notification" arrow>
              <IconButton sx={{ color: 'white' }} onClick={toggleNotifications}>
                <Badge badgeContent={getNotificationsCount} color="primary" variant="standard">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip> */}
          </Box>

          {/* <Tooltip title="Manage Account" arrow>
            <IconButton sx={{ color: 'white' }} component={RouterLink} to="/app/settings">
              <SettingsIcon style={{ height: '21px' }} />
            </IconButton>
          </Tooltip> */}

          {/* <Hidden mdDown>
            <Tooltip title="Logout" arrow>
              <IconButton sx={{ color: 'white' }} onClick={handleLogout}>
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Hidden> */}

          {/* <Hidden lgUp>
            <IconButton sx={{ color: 'white' }} onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden> */}
        </Box>
      </Toolbar>

      {/* <Popover
        open={isShortcutsOpen}
        anchorEl={shortcutsAnchorEl}
        onClose={toggleShortcuts}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Fade}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '16px',
          },
        }}
      >
        <Stack sx={{ minHeight: 250, minWidth: 250 }}>
          <Box sx={{ display: 'flex', flex: 1 }}>
            <Box
              sx={{
                border: '1px solid #eee',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                gap: 1,
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.dark',
                  backgroundColor: 'background.default',
                },
              }}
            >
              <Add
                sx={{
                  backgroundColor: '#0b66e41a',
                  width: 36,
                  height: 36,
                  p: 0.8,
                  borderRadius: '50%',
                }}
              />
              Add Version
            </Box>
            <Box
              sx={{
                border: '1px solid #eee',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                gap: 1,
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.dark',
                  backgroundColor: 'background.default',
                },
              }}
            >
              <Add
                sx={{
                  backgroundColor: '#0b66e41a',
                  width: 36,
                  height: 36,
                  p: 0.8,
                  borderRadius: '50%',
                }}
              />
              Add Funds
            </Box>
          </Box>
          <Box
            sx={{
              border: '1px solid #eee',
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              gap: 1,
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.dark',
                backgroundColor: 'background.default',
              },
            }}
          >
            <Add
              sx={{
                backgroundColor: '#0b66e41a',
                width: 36,
                height: 36,
                p: 0.8,
                borderRadius: '50%',
              }}
            />
            Create Game
          </Box>
        </Stack>
      </Popover>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        onClose={toggleNotifications}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={Fade}
        sx={{
          '& .MuiPopover-paper': {
            borderRadius: '16px',
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px',
              margin: '8px 0px',
            }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1, mt: 0.5, fontWeight: 'bold' }}>
              Notifications
            </Typography>
            {getNotifications.length > 0 && (
              <Button
                variant="outlined"
                sx={{ textTransform: 'none', mr: '4px' }}
                onClick={() => handleClearAllNotifications()}
              >
                Mark all
              </Button>
            )}
          </Box>
          <div
            id="scrollableDiv"
            style={{
              height: 400,
              overflow: 'auto',
              display: 'flex',
            }}
          >
            <InfiniteScroll
              dataLength={getNotifications.length}
              next={() => {
                setTimeout(() => {
                  setPageSize((prevPageSize) => prevPageSize + 10)
                }, 500)
              }}
              hasMore={getNotifications.length < getNotificationsCount}
              loader={<h4 style={{ textAlign: 'center', padding: '10px' }}>Loading...</h4>}
              endMessage={
                <p style={{ textAlign: 'center', padding: '10px' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
              scrollableTarget="scrollableDiv"
            >
              <Stack
                sx={{
                  minHeight: '400px',
                  width: matches ? '400px' : '100%',
                  padding: '12px',
                  background:
                    theme.palette.mode === 'dark' ? theme.palette.background.default : '#F5F6F8',

                  overflowY: 'auto',
                }}
                spacing={2}
              >
                {getNotifications.map((notification: any, index: number) => (
                  <Alert
                    key={index}
                    severity="info"
                    sx={{
                      width: '100%',
                      borderRadius: '14px',
                      padding: '15px',
                      background:
                        theme.palette.mode === 'dark' ? theme.palette.background.paper : '',
                    }}
                    onClose={() => handleClearNotification(notification.id)}
                  >
                    <AlertTitle>
                      <span>{notification.message}</span>
                    </AlertTitle>
                    <Typography variant="caption">
                      {moment
                        .utc(notification.createdAt)
                        .tz(moment.tz.guess())
                        .format('MMMM Do YYYY, h:mm:ss a')}
                    </Typography>
                  </Alert>
                ))}
              </Stack>
            </InfiniteScroll>
          </div>
        </Box>
      </Popover> */}
    </AppBar>
  )
}

export default TopBar
