import React from 'react'
import { Container, Typography } from '@mui/material'

const PrivacyPolicy = () => {
  return (
    <Container sx={{ mt: '90px' }}>
      <Typography
        textAlign="center"
        fontWeight="bold"
        fontSize="35px"
        sx={{ textTransform: 'uppercase' }}
      >
        Privacy Policy
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px">
        Privacy Policy
      </Typography>
      <Typography>
        This privacy policy administers the customer&apos;s or user&apos;s (&quot;you&quot;,
        &quot;your&quot;) utilization of the product The Galaxy Games(&quot;Software&quot;) that was
        made by The Galaxy GamesGroup just as our site - https://www.thegigabytegroup.com/. The
        Galaxy GamesGroup (also alluded to as &quot;the organization&quot;, &quot;we&quot;,
        &quot;us&quot;, or &quot;our&quot;) comprehends that you know about and care about your
        privacy interests, and we approach that in a serious way. We will completely follow all
        relevant Data Protection and client regulations every now and then in place. This Privacy
        Policy portrays the organization&apos;s arrangements and works on in regards to its
        assortment and utilization of your Personal Information and which freedoms you have with
        respect to them.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        User-Provided Information and how we use them
      </Typography>
      <Typography mb={1}>
        The product acquires the data you give when you register or sign up. Register or sign up
        with us is optional. Notwithstanding, kindly remember that you will most likely be unable to
        utilize a portion of the elements presented by the product except if you register with us or
        sign up. At the point when you register with us and utilize the Software, you generally
        provide:
      </Typography>
      <ol>
        <li>
          <Typography>
            Your name, contact number, email address, user name, password, and other registration
            data;
          </Typography>
        </li>
        <li>
          <Typography>Information you give us when you get in touch with us for help;</Typography>
        </li>
        <li>
          <Typography>
            Information you go into our system when utilizing the Software, like contact info, and
            so on
          </Typography>
        </li>
      </ol>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Purpose
      </Typography>
      <Typography mb={1}>We use Your Information to:</Typography>
      <ul>
        <li>
          <Typography>Create your account.</Typography>
        </li>
        <li>
          <Typography>Create things that are relevant to you.</Typography>
        </li>
        <li>
          <Typography>Verify your identity for security purposes</Typography>
        </li>
        <li>
          <Typography>
            For statistical or survey purposes to improve this website and its services to you.
          </Typography>
        </li>
        <li>
          <Typography>Enable you to find services and information quickly.</Typography>
        </li>
        <li>
          <Typography>
            Ensure that you don&apos;t have to add information more than once; this makes the
            website easier for you to navigate.
          </Typography>
        </li>
        <li>
          <Typography>Send you email about our services</Typography>
        </li>
      </ul>
      <Typography mt={1} fontWeight="bold">
        You agree that you do not object to us contacting you whether, by telephone, e-mail, or in
        writing and you confirm that you do not and will not consider any of the above as being a
        breach of any of your rights under the relevant data laws.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Automatically collected Information
      </Typography>
      <Typography>
        In addition, the software may collect certain information automatically, including, but not
        limited to, the type of device you use, your device unique device ID, the IP address of your
        device, your device operating system, the variety of Internet browsers you use, and
        information about the way you use the Software. When you use the software, we may use GPS
        technology (or other similar technology) to determine your current location to determine the
        city you are located within and display a location map with relevant advertisements. We will
        not share your current location with other users or partners. If you do not want us to use
        your location for the purposes set forth above, you should turn off the location services
        for the software.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Legitimate Interest
      </Typography>
      <Typography mb={1}>
        We will share your information with third parties only in the ways described in this privacy
        statement. We may disclose User Provided and Automatically Collected Information:
      </Typography>
      <Typography mb={1}>
        As required by law, such as to comply with a subpoena or similar legal process;
      </Typography>
      <Typography mb={1}>
        When we believe in good faith that disclosure is necessary to protect our rights, protect
        your safety or the safety of others, investigate fraud, or respond to a government request;
      </Typography>
      <Typography mb={1}>
        With our trusted services providers who work on our behalf, do not have an independent use
        of the information we disclose to them and have agreed to adhere to the rules outlined in
        this privacy statement;
      </Typography>
      <Typography>
        If the company is involved in a merger, acquisition, or sale of all or a portion of its
        assets, you will be notified via email and/or a prominent notice on our Web site of any
        change in ownership or uses of this information, as well as any choices you may have
        regarding this information.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        What are my opt-out rights?
      </Typography>
      <Typography>
        You can stop all collection of information by the software easily by uninstalling or
        disabling the software, where applicable.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Data Retention Policy, Managing Your Information
      </Typography>
      <Typography>
        We will retain User-Provided data for as long as you use the Software and for a reasonable
        time thereafter. We will retain Automatically Collected information for up to 24 months and
        thereafter may store it in aggregate. Please note that some or all of the User-Provided Data
        may be required for the Software to function correctly.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Security
      </Typography>
      <Typography>
        We are concerned about safeguarding the confidentiality of your information. We provide
        physical, electronic, and procedural safeguards to protect the information we process and
        maintain. For example, we limit access to this information to authorized employees and
        contractors who need to know that information to operate, develop or improve our Software.
        Please be aware that, although we endeavor to provide reasonable security for information we
        process and maintain, no security system can prevent all potential security breaches.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Media
      </Typography>
      <Typography>
        If you upload images to the website, you should avoid uploading images with embedded
        location data (EXIF GPS) included. Visitors to the website can download and extract any
        location data from images on the website.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Embedded content from other websites
      </Typography>
      <Typography>
        Articles on this site may include embedded content (e.g. videos, images, articles, etc.).
        Embedded content from other websites behaves exactly as if the visitor has visited the other
        website. These websites may collect data about you, use cookies, embed additional
        third-party tracking, and monitor your interaction with that embedded content, including
        tracking your interaction with the embedded content if you have an account and are logged in
        to that website.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Data Processing
      </Typography>
      <Typography>
        Data is stored/processed by our cloud providers in all countries they operate. However, we
        assure you that we will take steps to ensure that your privacy is protected.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Changes
      </Typography>
      <Typography>
        This Privacy Policy may be updated from time to time for any reason. We will notify you of
        any changes to our Privacy Policy by posting the new Privacy Policy here and informing you
        via a pop-up message on the website, an email, or an in-software message. You are advised to
        regularly consult this privacy policy for any changes, as continued use is deemed approval
        of all changes.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Your Rights
      </Typography>
      <Typography mb={1}>
        Under the data protection laws, you have, with certain statutory exceptions, multiple rights
        concerning our processing of personal data. You have:
      </Typography>
      <ol>
        <li>
          <Typography>
            The right to request access to their personal data and to find out how they are
            processed;
          </Typography>
        </li>
        <li>
          <Typography>
            The right to correct or complete incorrect or incomplete information;
          </Typography>
        </li>
        <li>
          <Typography>
            The right to delete personal data for data processed illegally where the lawful basis is
            consent;
          </Typography>
        </li>
        <li>
          <Typography>The right to restrict personal data processing;</Typography>
        </li>
        <li>
          <Typography>
            The right to transfer personal data in electronic format (data portability)
          </Typography>
        </li>
      </ol>
      <Typography mt={1}>
        You have the right at any time to withdraw a consent previously given regarding our
        processing of your personal data. This does not include any data we are obliged to keep for
        administrative, legal, or security purposes.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Data Breach
      </Typography>
      <Typography>
        The Company has taken strong measures to protect the security of your personal information
        and to ensure that your choices for its intended use are honored. We take strong precautions
        to protect your data from loss, misuse, unauthorized access or disclosure, alteration, or
        destruction. However, in the event of unforeseeable circumstances leading to the data
        breach, you will be informed of such data breach within 72 hours of the identification of
        such breach.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Contact forms - Cookies
      </Typography>
      <Typography mb={1}>
        If you leave a comment on our website, you may opt-in to saving your name, email address,
        and website in cookies. These are for your convenience so that you do not have to fill in
        your details again when you leave another comment. These cookies will last for one year.
      </Typography>
      <Typography mb={1}>
        If you have an account and you log in to this site, we will set a temporary cookie to
        determine if your browser accepts cookies. This cookie contains no personal data and is
        discarded when you close your browser. When you log in, we will also set up several cookies
        to save your login information and your screen display choices. Login cookies last for two
        days, and screen options cookies last for a year.
      </Typography>
      <Typography mb={1}>
        If you select “Remember Me”, your login will persist for two weeks. If you log out of your
        account, the login cookies will be removed.
      </Typography>
      <Typography mb={1}>
        If you edit or publish an article, an additional cookie will be saved in your browser. This
        cookie includes no personal data and simply indicates the post ID of the article you just
        edited. It expires after 1 day.
      </Typography>
      <Typography mb={1}>
        If you leave a comment, the comment and its metadata are retained indefinitely. This is so
        we can recognize and approve any follow-up comments automatically instead of holding them in
        a moderation queue.
      </Typography>
      <Typography mb={1}>
        When visitors leave comments on the site, we collect the data shown in the comments form,
        and also the visitor’s IP address and browser user agent string to help spam detection.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Your Consent
      </Typography>
      <Typography>
        By using the software and the website, you are consenting to our processing of your
        information as outlined in this Privacy Policy now and as amended by us. &quot;Processing&quot;, means
        using cookies on a computer/hand-held device or using or touching information in any way,
        including, but not limited to, collecting, storing, deleting, using, combining, and
        disclosing information, all of which activities will take place in our servers
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Agreement and Litigation
      </Typography>
      <Typography>
        Using the software and our support services, you agree to the terms and conditions in this
        Privacy Policy and/or any other agreement that we might have with you. If you do not agree
        to any of these terms and conditions, you should use neither our software nor the website.
        You agree that any dispute over privacy or the terms contained in this Privacy Policy, or
        any other agreement we have with you, will be governed by the laws of the USA. You also
        agree to arbitrate such disputes (use the contact form) and to abide by any limitation on
        damages contained in any agreement we may have with you.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Users from Outside the USA
      </Typography>
      <Typography>
        Please note that if you are a user from outside the USA, your information may be processed,
        stored, and transferred in AITC, NP where our central database is operated. There is a
        possibility that the data protection laws using in the USA might not be as encompassing as
        those in your country. However, we assure you that we will take steps to ensure that your
        privacy is protected.
      </Typography>
      <Typography fontWeight="bolder" fontSize="25px" mt={3}>
        Contact Details, Complaints, and Questions
      </Typography>
      <Typography fontStyle='italic'>
        If you have any questions regarding our Privacy Policy, you can contact us through our
        contact form or email us. Last Updated: Thu 20 Jan 2022 10:27 AM
      </Typography>
    </Container>
  )
}

export default PrivacyPolicy
