import { useEffect, useState } from 'react'
import { Box, IconButton, Typography, TextField } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { debounce } from 'utils'
interface IPaginationInfo {
  currentPage: Number
  lastPage: Boolean
  pageSize: Number
  totalPage: Number
  totalRecord: Number
}
interface ITablePagination {
  handleChangeRowsPerPage: (e: any) => void
  handlePageChange: (e: any, n: number) => void
  paginationInfo: IPaginationInfo
}

const TablePagination = ({
  handleChangeRowsPerPage,
  handlePageChange,
  paginationInfo,
}: ITablePagination) => {
  const [invalidGotoPage, setInvalidGotoPage] = useState(false)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const selectStyled = {
    borderRadius: '4px',
    outline: 'none',
    cursor: 'pointer',
    width: 'auto',
    padding: '5px 8px',
    border: width < 600 ? '1.2px solid #808080' : '1px solid #808080',
    fontSize: width < 600 ? '1.3rem' : '0.8rem',
    margin: '0px',
  }

  const currentPage = paginationInfo?.currentPage
  const pageSize = paginationInfo?.pageSize
  const totalPage = paginationInfo?.totalPage
  const totalRecord = paginationInfo?.totalRecord

  const handleGotoPage = (e: any) => {
    const value = e.target.value
    const isValid = value <= totalPage && value > 0

    setInvalidGotoPage(false)

    if (isValid) {
      return handlePageChange(e, e.target.value)
    }

    setInvalidGotoPage(true)
  }
  return (
    <Box
      sx={{
        display: 'flex',
        backgroundColor: '#f5f5f5',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: { xs: '9px', lg: '18px' },
        padding: '12px 0px 0px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: { xs: '9px', lg: '18px' },
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: 'secondary.main',
            fontSize: 14,
            fontWeight: 600,
            // mobile view
            // '@media (max-width: 600px)': {
            //   fontSize: '1.5rem',
            // },
            // '@media (min-width: 600px) and (max-width: 960px)': {
            //   fontSize: '1.4rem',
            // },
          }}
        >
          Total Records: &nbsp;{totalRecord}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontSize: 14,
            color: 'secondary.main',
            fontWeight: 600,
            marginRight: '5px',
            // mobile view
            // '@media (max-width: 600px)': {
            //   fontSize: '1.5rem',
            // },
            // '@media (min-width: 600px) and (max-width: 960px)': {
            //   fontSize: '1.4rem',
            // },
          }}
        >
          Rows per page:
        </Typography>

        <select value={pageSize as number} onChange={handleChangeRowsPerPage} style={selectStyled}>
          {/* <option value={5}>5</option> */}
          <option value={10}>10</option>
        </select>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: { xs: '6px', lg: '12px' },
          }}
        >
          <IconButton
            disabled={currentPage.valueOf() === 1}
            onClick={(e) => {
              debounce(() => handlePageChange(e, currentPage.valueOf() - 1), 500)()
            }}
            size="large"
            sx={{ padding: '0px 0px 0px 10px' }}
          >
            <KeyboardArrowLeftIcon
              fontSize="inherit"
              sx={
                {
                  // mobile view
                  // '@media (max-width: 600px)': {
                  //   fontSize: '2.5rem',
                  // },
                  // '@media (min-width: 600px) and (max-width: 960px)': {
                  //   fontSize: '2.4rem',
                  // },
                }
              }
            />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: '14px',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontSize: 14,
                color: 'secondary.main',
                fontWeight: 600,
                // mobile view
                // '@media (max-width: 600px)': {
                //   fontSize: '1.5rem',
                // },
                // '@media (min-width: 600px) and (max-width: 960px)': {
                //   fontSize: '1.4rem',
                // },
              }}
            >
              Page:
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '6px',
              }}
            >
              <TextField
                type="number"
                value={currentPage}
                onChange={(e) => {
                  if (e?.target?.value) {
                    debounce(() => handleGotoPage(e), 500)()
                  }
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                    sx: {
                      padding: '3px 0px',
                      textAlign: 'center',
                      '&::-webkit-outer-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                      '&::-webkit-inner-spin-button': {
                        WebkitAppearance: 'none',
                        margin: 0,
                      },
                      // '@media (max-width: 600px)': {
                      //   fontSize: '1.5rem',
                      // },
                      // '@media (min-width: 600px) and (max-width: 960px)': {
                      //   fontSize: '1.4rem',
                      // },
                    },
                  },
                }}
                sx={{
                  width: '45px',
                  '& > div': {
                    borderRadius: '4px !important',
                  },
                }}
              />

              {invalidGotoPage && <Typography color="error">Invalid Page</Typography>}
            </Box>

            <Typography
              variant="h5"
              sx={
                {
                  // mobile view
                  // '@media (max-width: 600px)': {
                  //   fontSize: '1.5rem',
                  // },
                  // '@media (min-width: 600px) and (max-width: 960px)': {
                  //   fontSize: '1.4rem',
                  // },
                }
              }
            >
              of &nbsp;{totalPage}
            </Typography>
          </Box>

          <IconButton
            disabled={currentPage.valueOf() >= totalPage.valueOf()}
            onClick={(e) => {
              debounce(() => handlePageChange(e, currentPage.valueOf() + 1), 500)()
            }}
            sx={{ padding: '0px 0px 0px 10px' }}
            size="large"
          >
            <KeyboardArrowRightIcon
              fontSize="inherit"
              sx={{
                // mobile view
                '@media (max-width: 600px)': {
                  fontSize: '2.5rem',
                },
                '@media (min-width: 600px) and (max-width: 960px)': {
                  fontSize: '2.4rem',
                },
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  )
}

export default TablePagination
