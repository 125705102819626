import React from 'react'
import GameCategory from 'app/web/components/GameCategory'
import { Typography, Grid, Box, CircularProgress } from '@mui/material'
import GameContainer from 'app/web/components/GameContainer'
import DownloadApp from 'app/web/components/DownloadApp'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

const Games = () => {
  const { latestGames } = useSelector((state: RootState) => state.gameList)
  const { isLoading } = useSelector((state: RootState) => state.ui)

  return (
    <Box sx={{ marginTop: '90px', overflow: 'hidden' }}>
      <Typography variant="h1" textAlign="center">
        The Galaxy GamesGroup Games
      </Typography>

      <GameCategory />

      <DownloadApp />

      <Typography
        variant="h1"
        component="div"
        textAlign="center"
        color="black"
        fontWeight="bolder"
        mt={5}
        mb={2}
      >
        Latest Games!
      </Typography>

      <Grid container justifyContent="center" mt={3}>
        {isLoading?.usedFor === 'game' && isLoading.status ? (
          <CircularProgress color="success" />
        ) : (
          latestGames.map((latestGame, id) => (
            <GameContainer
              key={id}
              title={latestGame.title}
              image={latestGame.featuredImage}
              gameID={latestGame._id}
              description={latestGame.description}
              category={latestGame.category}
            />
          ))
        )}
      </Grid>
    </Box>
  )
}

export default Games
