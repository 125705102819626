import React from 'react'
import { Box } from '@mui/material'

interface EmbedIdInterface {
  embedId: string
}
const YoutubeEmbed = ({ embedId }: EmbedIdInterface) => {
  return (
    <Box sx={{ overflow: 'hidden', paddingBottom: {md: '50%', lg: '50%', sm: '60%', xs: '60%'}, position: 'relative', height: 0 }}>
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        style={{ left: 0, top: 0, height: '80%', width: '100%', position: 'absolute' }}
      />
    </Box>
  )
}

export default YoutubeEmbed
