import React, { useState, FormEvent } from 'react'
import { Container, Typography, Stack, TextField, Box } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { RootState } from 'stores'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'app/web/components/Form'
import { createContact } from 'stores/contact/contact-actions'

const regEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/

const Contact = () => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector((state: RootState) => state.ui)
  const [formData, setFormData] = useState<{
    firstName: string
    lastName: string
    email: string
    phone: string
    address: string
    message: string
  }>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    message: '',
  })

  const [errors, setErrors] = useState({
    emailError: false,
    phoneError: false,
    addressError: false,
    messageError: false,
    emailErrorText: '',
    phoneErrorText: '',
    addressErrorText: '',
    messageErrorText: '',
  })

  const validate = () => {
    const temp = {
      email: '',
      phone: '',
      address: '',
      message: '',
    }
    temp.email = regEmail.test(formData.email) ? '' : 'Email is not Valid'
    temp.phone = formData.phone.length >= 10 ? '' : 'Ivalid Phone Number'
    temp.address = formData.address ? '' : 'This Field is required'
    temp.message = formData.message ? '' : 'This Field is required'

    setErrors({
      emailError: temp.email ? true : false,
      phoneError: temp.phone ? true : false,
      addressError: temp.address ? true : false,
      messageError: temp.message ? true : false,
      emailErrorText: temp.email,
      phoneErrorText: temp.phone,
      addressErrorText: temp.address,
      messageErrorText: temp.message,
    })

    return Object.values(temp).every((value) => value === '')
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (validate()) {
      dispatch(createContact({ ...formData }))
    }
  }

  return (
    <Container sx={{ mt: '180px', mb: '150px' }}>
      <Form>
        <Box
          component="form"
          sx={{
            mx: 'auto',
            mt: { xs: 3, md: 0 },
            p: 3,
            pl: 8,
            pr: 8,
          }}
        >
          <Typography variant="h1" mb={4}>
            Get In Touch
          </Typography>

          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={1}>
              <TextField
                label="First Name"
                value={formData.firstName}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
              />

              <TextField
                label="Last Name"
                value={formData.lastName}
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
              />
            </Stack>

            <TextField
              label="Email"
              value={formData.email}
              variant="outlined"
              size="small"
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
              error={errors.emailError}
              helperText={errors.emailErrorText}
            />

            <TextField
              label="Contact Number"
              value={formData.phone}
              variant="outlined"
              size="small"
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              required
              error={errors.phoneError}
              helperText={errors.phoneErrorText}
              inputProps={{ maxLength: 10 }}
            />

            <TextField
              label="Address"
              value={formData.address}
              variant="outlined"
              size="small"
              onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              required
              error={errors.addressError}
              helperText={errors.addressErrorText}
            />

            <TextField
              multiline
              maxRows={4}
              label="Leave a Message"
              value={formData.message}
              variant="outlined"
              size="small"
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              required
              error={errors.messageError}
              helperText={errors.messageErrorText}
            />
          </Stack>

          <Box sx={{ display: 'flex', justifyContent: 'left', mt: 3 }}>
            <LoadingButton
              loading={isLoading?.usedFor === 'contact' && isLoading.status}
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Send Message
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </Container>
  )
}

export default Contact
