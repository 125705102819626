import React from 'react'
import {Box, Button} from '@mui/material'
import AndroidIcon from '@mui/icons-material/Android'
import AppleIcon from '@mui/icons-material/Apple'

const DownloadButtons = () => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 4 }}>
        <Button
          variant="contained"
          startIcon={<AndroidIcon />}
          sx={{ mr: 2, padding: '12px', border: '1px solid gray' }}
        >
          Android
        </Button>
        <Button
          variant="contained"
          startIcon={<AppleIcon />}
          sx={{ padding: '12px', border: '1px solid gray' }}
        >
          <a
            href="itms-services://?action=download-manifest&url=https://api.thegigabytegroup.com/app/ios/manifest.plist"
            id="text"
            style={{ color: 'white' }}
          >
            {' '}
            IoS
          </a>
        </Button>
      </Box>
    )
}

export default DownloadButtons
