import React from 'react'
import { Container, Typography, List, Stack } from '@mui/material'
import StageList from 'app/web/components/StageList'

const Product = () => {
  return (
    <Container sx={{ marginTop: '90px' }}>
      <Typography variant="h1" textAlign="left" mb={3}>
        Why Choose The Galaxy GamesGroup
      </Typography>
      <Typography textAlign="left" mb={1}>
        At The Galaxy GamesGroup, we esteem our clients more than whatever else. With our first class
        developers working with the latest technology, The Galaxy GamesGroup remains in its own spot in
        the realm of online gaming. With our application, you assume total responsibility for the
        customization and can begin acquiring significant yields at low speculation.
      </Typography>
      <Typography textAlign="left" mb={6}>
        We boast as an elite developer and provider of this application that is created by experts
        who have been working in this field for a really long time.
      </Typography>
      <Typography variant="h1" textAlign="left" mb={3}>
        Advantages of The Galaxy GamesGroup
      </Typography>
      <Typography textAlign="left">
        Initially, We make progress toward greatness in online gaming. Joining technical mastery
        with inventive drive, our group plans to foster a game that is functional, straightforward,
        and unmistakable. With all our fish games and slot games, we need advancement to be a steady
        nature of our application and in this manner are recharging our games continually. Here are
        a portion of the elements that make us stick out.
      </Typography>
      <List component={Stack}>
        <StageList
          mb={1}
          md={3}
          sm={4}
          xs={5}
          lg={2}
          listTitle="1. Experience"
          listDesc="The Galaxy GamesGroup has been in this for more years than any other gaming platforms. We have been specializing in this game for more than any other gaming apps that are available."
        />
        <StageList
          mb={1}
          md={3}
          sm={4}
          xs={5}
          lg={2}
          listTitle="2. Marketing"
          listDesc="We have exported nearly 5000 fish game tables to the United States alone in the last five years. Moreover, we have helped our distributors customize hundreds of fish games and slot games. It would not have been possible without out trust and visibility in the market."
        />
        <StageList
          mb={1}
          md={3}
          sm={4}
          xs={5}
          lg={2}
          listTitle="3. Strength"
          listDesc="ven before our online endeavours, we have spent considerable time in the physical fish machine market and accumulated valuable experience in the traditional fish game table market."
        />
        <StageList
          mb={1}
          md={3}
          sm={4}
          xs={5}
          lg={2}
          listTitle="4. Team"
          listDesc="We are proud of nothing more than our development team that comprise of the best of the best in software development."
        />
        <StageList
          mb={1}
          md={3}
          sm={4}
          xs={5}
          lg={2}
          listTitle="5. Innovation"
          listDesc="We have launched a new online fish game app at a time when the market needs it the most. At the same time, we have worked on providing a special customization edition of our apps."
        />
        <StageList
          mb={1}
          md={3}
          sm={4}
          xs={5}
          lg={2}
          listTitle="6. Our Mission"
          listDesc="We hope to work with excellent partners to achieve brilliant results."
        />
      </List>
      <Typography variant="h1" textAlign="left" mb={3} mt={3}>
        What can The Galaxy GamesGroup bring to customers?
      </Typography>
      <List component={Stack}>
        <StageList
          mb={1}
          md={2}
          sm={2}
          xs={3}
          lg={1}
          flex={50}
          listTitle="1"
          listDesc="Resell credits to customers."
        />
        <StageList
          mb={1}
          md={2}
          sm={2}
          xs={3}
          lg={1}
          flex={50}
          listTitle="2"
          listDesc="Game Holding 10% to 12%."
        />
        <StageList
          mb={1}
          md={2}
          sm={2}
          xs={3}
          lg={1}
          flex={50}
          listTitle="3"
          listDesc="No split profit with you."
        />
        <StageList
          mb={1}
          md={2}
          sm={2}
          xs={3}
          lg={1}
          flex={50}
          listTitle="4"
          listDesc="Customization is always available. Expand your influence and visibility. App name, logo, lobby style, game icons, music, etc. are all customizable."
        />
      </List>
    </Container>
  )
}

export default Product
