import api from 'config/apiConfig'
import { userActions, IUserState, IUpdateWallet } from 'stores/user/user-slice'
import { uiActions } from 'stores/ui/ui-slice'
import { authActions } from 'stores/auth/auth-slice'
import { ServerError } from 'stores/auth/auth-actions'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'

export const getLoggedUserInfo =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const { data } = await api.get<IUserState>('/users/me')
      dispatch(userActions.setUserInfo(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    }
  }

export const getUsers =
  (pageNumber: number, rowsPerPage: number = 10, searchKey: string = '') =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'userList' }))
      const { data } = await api.get<{ data: IUserState[]; paginationInfo: any }>(
        `/users/under/me?pageNumber=${pageNumber}&size=${rowsPerPage}&searchKey=${searchKey}`
      )
      dispatch(userActions.setUserList(data.data))
      return data?.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'userList' }))
    }
  }

export const getAccountManager =
  (pageNumber: number, rowsPerPage: number = 10, searchKey: string = '') =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'adminList' }))
      const { data } = await api.get<{ data: IUserState[]; paginationInfo: any }>(
        `/users/account-manager/account-list?pageNumber=${pageNumber}&size=${rowsPerPage}&searchKey=${searchKey}`
      )
      dispatch(userActions.setAdminList(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'adminList' }))
    }
  }

interface IUserData {
  account: string
  nickname: string
  role: string
  password: string
}

export const createUser =
  (userData: IUserData) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'newUserReg' }))
      const { data } = await api.post('/users', userData)
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'New user created successfully!',
        })
      )

      dispatch(userActions.updateUserList(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'newUserReg' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 6000)
    }
  }

export const resetUserPassword =
  (oldPassword: string, newPassword: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'userActions' }))
      const { data } = await api.post('/users/change/password', { oldPassword, newPassword })

      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: data.success,
        })
      )

      await api.post('/users/logout')

      setTimeout(() => {
        localStorage.removeItem('auth')
        dispatch(authActions.logout())
        window.location.href = '/login'
      }, 2000)
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'userActions' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const resetCustomerPassword =
  (newPassword: string, customerId: string | undefined, onSuccess?: () => void) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'userActions' }))

      if (!customerId) {
        throw new Error()
      }

      interface IBody {
        targetUserId: string
        newPassword: string
      }

      const postData: IBody = {
        targetUserId: customerId,
        newPassword,
      }
      const { data } = await api.post('/users/change/password', postData)
      onSuccess && onSuccess()
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: data.success,
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'userActions' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 5000)
    }
  }

export const rechargeCustomerBlc =
  (customerId: string, nickname: string, recharge: number, note: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'userActions' }))

      interface IBody {
        nickname: string
        recharge: number
        note: string
      }

      const postData: IBody = {
        nickname,
        recharge,
        note,
      }

      const { data } = await api.post('/balance/recharge', postData)
      dispatch(userActions.rechargeWallet({ targetId: customerId, amount: recharge, note }))
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: data.success,
        })
      )
    } catch (error: any) {
      handleErrorResponse(error, dispatch)
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'userActions' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 5000)
    }
  }

export const redeemBlc =
  (customerId: string, nickname: string, redeem: number, note: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'userActions' }))
      const { data } = await api.post('/balance/redeem', { nickname, redeem, note })
      dispatch(
        userActions.redeemWallet({
          targetId: customerId,
          amount: redeem,
          note: '',
        })
      )
      dispatch(uiActions.showNotification({ status: 200, title: 'success', msg: data.success }))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'userActions' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 5000)
    }
  }

export const searchUser =
  (searchKey: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'global' }))

      const { data } = await api.post('/users/search', { searchKey })

      dispatch(userActions.setUserList(data.data))
      return data.paginationInfo
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'global' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 3000)
    }
  }

export const searchAccountManager =
  (pageNumber: number, rowsPerPage: number = 10, searchKey: string = '') =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'global' }))

      const { data } = await api.get(
        `/users/account-manager/account-list?pageNumber=${pageNumber}&size=${rowsPerPage}&searchKey=${searchKey}`
      )
      dispatch(userActions.setAdminList(data))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'global' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 6000)
    }
  }

export const updateSuperAdminBlc =
  (customerId: string, amount: number, note: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      const postData: IUpdateWallet = {
        targetId: customerId,
        amount,
        note,
      }

      const { data } = await api.patch('/admin/update/balance', postData)

      dispatch(userActions.updateSuperAdminBlc(postData))

      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: `New Balance is: ${data.newBalance.toFixed(2)}`,
        })
      )
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'userActions' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 5000)
    }
  }

export const updateUserStatus =
  (userId: string, role: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      interface IResponse {
        newStatus: 'active' | 'inactive'
      }
      const { data } = await api.post<IResponse>('/users/change/status', { userId })
      dispatch(
        uiActions.showNotification({
          status: 200,
          title: 'success',
          msg: 'New status updated successfully!',
        })
      )
      dispatch(userActions.updateUserStatus({ targetId: userId, status: data.newStatus, role }))
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'newUserReg' }))
      setTimeout(() => {
        dispatch(uiActions.hideNotification())
      }, 4000)
    }
  }

export const getSelectedAdmin =
  (admin: IUserState) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch(userActions.setSelectedAdmin(admin))
  }

export const getSelectedUser =
  (user: IUserState) =>
  (dispatch: Dispatch<Action>): void => {
    dispatch(userActions.setSelectedUser(user))
  }
