import React, { useState, FormEvent } from 'react'
import {
  Container,
  Typography,
  Box,
  Stack,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { RootState } from 'stores'
import { useDispatch, useSelector } from 'react-redux'
import Form from 'app/web/components/Form'
import { createDistributor } from 'stores/distribution/distributor-actions'

const regEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/

const Stores = () => {
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.ui)

  const [formData, setFormData] = useState<{
    fullName: string
    email: string
    city: string
    state: string
    zip: string
    phone: string
    department: string
    message: string
  }>({
    fullName: '',
    email: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    department: '',
    message: '',
  })

  const [errors, setErrors] = useState({
    emailError: false,
    phoneError: false,
    departmentError: false,
    messageError: false,
    emailErrorText: '',
    phoneErrorText: '',
    departmentErrorText: '',
    messageErrorText: '',
  })

  const validate = () => {
    const temp = {
      email: '',
      phone: '',
      department: '',
      message: '',
    }
    temp.email = regEmail.test(formData.email) ? '' : 'Email is not Valid'
    temp.phone = formData.phone.length >= 10 ? '' : 'Invalid Phone Number'
    temp.department = formData.department ? '' : 'This Field is required'
    temp.message = formData.message ? '' : 'This Field is required'

    setErrors({
      emailError: temp.email ? true : false,
      phoneError: temp.phone ? true : false,
      departmentError: temp.department ? true : false,
      messageError: temp.message ? true : false,
      emailErrorText: temp.email,
      phoneErrorText: temp.phone,
      departmentErrorText: temp.department,
      messageErrorText: temp.message,
    })

    return Object.values(temp).every((value) => value === '')
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (validate()) {
      dispatch(createDistributor({ ...formData, zip: +formData.zip, phone: +formData.phone }))
      setTimeout(() => {
        setFormData({
          fullName: '',
          email: '',
          city: '',
          state: '',
          zip: '',
          phone: '',
          department: '',
          message: '',
        })
      }, 1000)
    }
  }
  return (
    <Container sx={{ marginTop: '90px' }}>
      <Typography variant="h1" textAlign="left">
        Stores and Distribution

      </Typography>

      <Typography mt={3}>
        Is it safe to say that you are into online ability reel and fish games? Would you like us to
        give you the skill games online to help your business? The Galaxy Gamesmerchants can
        purchase virtual credits with us, so &quot;credit&quot; is the item we are selling. Assuming
        that you have Larger deals volumes and more extensive channels, we can set you up with a
        higher wholesaler level, and the benefits space we give will turn bigger. With the solid
        help of our application development group, we got your back constantly. We are searching for
        merchants who are business spurred in every one of the 50 US states. The Galaxy GamesGroup
        gives circulation to more conspicuous merchants, and those wholesalers give to sub-merchant
        and stores. Assuming we get any grumbling in regards to the abuse of the product, then, at
        that point, we will make a move legitimately.
      </Typography>

      <Typography variant="h1" textAlign="left" mt={6}>
        Application Form for Distributors/Stores
      </Typography>

      <Typography mt={3}>
        Below is the form you need to fill to open a distributor or store account. We will reach you
        after we have gotten the form. Much obliged to you for reaching us, and we are anticipating
        work with you later on. The Galaxy Gamesis the original developer of The GigaBytte Group.
        The Galaxy GamesGroup application online doesn&apos;t underwrite with players
        straightforwardly. If it&apos;s not too much trouble, contact the distributors and stores
        straightforwardly for the accompanying inquiries:
      </Typography>

      <Box mt={4} pl={3} mb={5}>
        <ul>
          <li>
            <Typography>We do not provide forgotten PASSWORD</Typography>
          </li>

          <li>
            <Typography>We do not accept player account</Typography>
          </li>

          <li>
            <Typography>We do not accept to recharge and cash out</Typography>
          </li>

          <li>
            <Typography>We do not accept download APP for players</Typography>
          </li>

          <li>
            <Typography>Please leave your business requirement</Typography>
          </li>
        </ul>
      </Box>

      <Form>
        <Box
          component="form"
          sx={{
            mx: 'auto',
            mt: { xs: 3, md: 0 },
            mb: 3,
            p: 3,
            pl: { md: 5, xs: 3, lg: 8, sm: 3 },
            pr: { md: 5, xs: 3, lg: 8, sm: 3 },
          }}
        >
          <Stack spacing={3}>
            <TextField
              label="Full Name"
              variant="outlined"
              size="small"
              value={formData.fullName}
              onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
            />

            <TextField
              label="Email"
              variant="outlined"
              size="small"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
              error={errors.emailError}
              helperText={errors.emailErrorText}
            />

            <TextField
              label="Contact Number"
              variant="outlined"
              size="small"
              value={formData.phone}
              onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              required
              error={errors.phoneError}
              helperText={errors.phoneErrorText}
              inputProps={{ maxLength: 10 }}
            />

            <Stack direction="row" justifyContent="center" spacing={1}>
              <TextField
                label="City"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.city}
                onChange={(e) => setFormData({ ...formData, city: e.target.value })}
              />

              <TextField
                label="State"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.state}
                onChange={(e) => setFormData({ ...formData, state: e.target.value })}
              />
            </Stack>

            <Stack direction="row" justifyContent="center" spacing={1}>
              <TextField
                label="Zip"
                variant="outlined"
                size="small"
                fullWidth
                value={formData.zip}
                onChange={(e) => setFormData({ ...formData, zip: e.target.value })}
              />

              <FormControl fullWidth size="small" error={errors.departmentError}>
                <InputLabel id="demo-simple-select-label" required>
                  Department
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formData.department}
                  label="Department"
                  onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                >
                  <MenuItem value="distributor">Distributor</MenuItem>

                  <MenuItem value="storeOperator">Store Operator</MenuItem>
                </Select>

                <FormHelperText>{errors.departmentErrorText}</FormHelperText>
              </FormControl>
            </Stack>

            <TextField
              multiline
              maxRows={4}
              label="Leave a Message"
              variant="outlined"
              size="small"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              required
              error={errors.messageError}
              helperText={errors.messageErrorText}
            />
          </Stack>

          <Box sx={{ display: 'flex', justifyContent: 'left', mt: 3 }}>
            <LoadingButton
              loading={isLoading?.usedFor === 'distributor' && isLoading.status}
              type="submit"
              variant="contained"
              onClick={handleSubmit}
            >
              Send Message
            </LoadingButton>
          </Box>
        </Box>
      </Form>
    </Container>
  )
}

export default Stores
