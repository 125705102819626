import React, { useEffect, useRef } from 'react'
import { Typography, Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { Link } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    backgroundSize: 'cover',
    opacity: 1,
    height: '220px',
    borderRadius: '4px',
    boxShadow: 'rgba(108, 122, 137, 1) 0px 5px 15px',
  },
}))
interface GameInterface {
  title: string
  image?: string
  gameID: any
  description?: any
  category: string
}
const GameContainer = ({ title, gameID, description, category }: GameInterface) => {
  const classes = useStyles()
  const wrapperRef = useRef(null)

  useEffect(() => {
    gsap.fromTo(
      wrapperRef.current,
      { y: -10 },
      {
        rotation: 0,
        y: 0,
        duration: 1,
        autoAlpha: 1,
        ease: 'none',
        scrollTrigger: {
          id: 'game-wrapper',
          trigger: wrapperRef.current,
          start: 'top center+=200',
        },
      }
    )
  }, [])

  return (
    <Grid
      item
      sm={8}
      xs={12}
      md={4}
      lg={3}
      m={1}
      mb={{ sm: 10, xs: 10, md: 10, lg: 10 }}
      ref={wrapperRef}
      id="game-wrapper"
      className={classes.wrapper}
      component={Link}
      to={`/games/${gameID}}`}
      state={{ title: title, description: description, gameId: gameID, category: category }}
      sx={{
        backgroundImage: `url('/static/images/games/game_bg.jpg')`,
        '&.MuiGrid-root': { '&:hover': { transform: 'translate(0,2) scale(2.5)' } },
      }}
    >
      <Typography fontWeight="bolder" color="black" mt={30} fontSize={30} textAlign="center">
        {title}
      </Typography>
    </Grid>
  )
}

export default GameContainer
