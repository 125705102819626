import React from 'react'
import { Container, Typography, Box, Grid, CircularProgress } from '@mui/material'
import { useLocation } from 'react-router-dom'
import YoutubeEmbed from 'app/web/components/YoutubeEmbed'
import GameDetailComponent from 'app/web/components/GameDetail'
import Screenshots from 'app/web/components/Screenshots'
import GameContainer from 'app/web/components/GameContainer'
import DownloadApp from 'app/web/components/DownloadApp'
import GameSlider from 'app/web/components/GameSlider'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

const GameDetail = () => {
  const location = useLocation()
  const { title, description, gameId, category } = location.state as any
  const { latestGames } = useSelector((state: RootState) => state.gameList)
  const { isLoading } = useSelector((state: RootState) => state.ui)

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <Container sx={{ marginTop: '90px' }}>
        <Typography variant="h1" textAlign="center" mb={2}>
          {title}
        </Typography>

        <GameDetailComponent description={description} />

        <YoutubeEmbed embedId="NgzXFo9VaHg" />
        {/* <Box mt={-9} sx = {{ justifyContent: 'right', display: 'flex'}}>
            <Button
              variant="contained"
              sx={{ borderRadius: '18px'}}
              component={Link}
              to="/join"
            >
              Register Now
            </Button>
          </Box> */}
      </Container>
      <Grid container justifyContent="center">
        <Screenshots />
        <Screenshots />
        <Screenshots />
        <Screenshots />
        <Screenshots />
        <Screenshots />
      </Grid>

      <Typography
        variant="h1"
        component="div"
        textAlign="center"
        color="black"
        fontWeight="bolder"
        mt={5}
        mb={4}
      >
        Similar Games
      </Typography>

      <GameSlider gameId={gameId} category={category} />

      <DownloadApp />

      <Typography
        variant="h1"
        component="div"
        textAlign="center"
        color="black"
        fontWeight="bolder"
        mt={5}
        mb={2}
      >
        Latest Games!
      </Typography>

      <Grid container justifyContent="center" mt={3}>
        {isLoading?.usedFor === 'game' && isLoading.status ? (
          <CircularProgress color="success" />
        ) : (
          latestGames.map((latestGame, id) => (
            <GameContainer
              key={id}
              title={latestGame.title}
              image={latestGame.featuredImage}
              gameID={latestGame._id}
              description={latestGame.description}
              category={latestGame.category}
            />
          ))
        )}
      </Grid>
    </Box>
  )
}

export default GameDetail
