import React, { useState, useEffect } from 'react'
import { Chip, Grid, CircularProgress, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from 'stores'
import { getCategory } from 'stores/game/game-category-actions'
import { getGameByCategory } from 'stores/game/game-action'
import GameContainer from './GameContainer'

const Root = styled('ul')(({ theme }) => ({
  margin: 0,
  padding: 0,
  listStyle: 'none',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(3),
  '& li': {
    margin: theme.spacing(0.5),
  },
}))
const GameCategory = () => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState(0)
  const [category, setCategory] = useState<string>('')

  const { gameCategory } = useSelector((state: RootState) => state.category)
  const { gameByCategory } = useSelector((state: RootState) => state.gameList)
  const { isLoading } = useSelector((state: RootState) => state.ui)

  useEffect(() => {
    dispatch(getCategory(1, 5))
  }, [dispatch])

  useEffect(() => {
    if (gameCategory.length !== 0) {
      setTimeout(() => {
        setCategory(gameCategory[0]._id)
      }, 1000)
    }
  }, [gameCategory])

  useEffect(() => {
    dispatch(getGameByCategory(category))
  }, [dispatch, category])

  const handleClick = (idx: any, id: string) => {
    setSelected(idx)
    setCategory(id)
  }
  return (
    <>
      <Root>
        {gameCategory.map((cat, idx) => {
          return (
            <li key={idx}>
              <Chip
                clickable
                component="a"
                size="medium"
                variant={selected === idx ? 'filled' : 'outlined'}
                rel="nofollow"
                label={cat.name}
                onClick={() => handleClick(idx, cat._id)}
                color="success"
                sx={{
                  '&.MuiChip-root': {
                    padding: '15px',
                    paddingTop: '18px',
                    paddingBottom: '18px',
                    borderRadius: '30px',
                    fontSize: '16px',
                  },
                }}
              />
            </li>
          )
        })}
      </Root>
      <Grid container justifyContent="center" mt={3} mb={10}>
        {isLoading?.usedFor === 'gameByCategory' && isLoading.status ? (
          <CircularProgress color="success" />
        ) : category && gameByCategory.length > 0 ? (
          gameByCategory.map(
            (game) =>
              game.category && (
                <GameContainer
                  key={game._id}
                  title={game.title}
                  image={game.featuredImage}
                  gameID={game._id}
                  description={game.description}
                  category={game.category}
                />
              )
          )
        ) : (
          <Box py={3}>
            <Typography>No Games Found</Typography>
          </Box>
        )}
      </Grid>
    </>
  )
}

export default GameCategory
