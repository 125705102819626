import React from 'react'
import { Grid } from '@mui/material'

const Screenshots = () => {
  return (
    <Grid
      item
      sm={8}
      xs={12}
      md={4}
      lg={3}
      m={1}
      sx={{
        backgroundImage: `url('https://cdn.pocket-lint.com/r/s/660x/assets/images/141774-games-feature-mordhau-image3-6tcxfxmuv2-jpg.webp?v1')`,
        backgroundSize: 'cover',
        opacity: 1,
        height: '230px',
        borderRadius: '4px',
        boxShadow: 'rgba(108, 122, 137, 1) 0px 5px 15px',
      }}
    ></Grid>
  )
}

export default Screenshots
