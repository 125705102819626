import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import LoadingButton from '@mui/lab/LoadingButton'
import IconButton from '@mui/material/IconButton'
import PersonIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/Lock'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import PageTitle from 'app/dashboard/components/PageTitle'
import { login } from 'stores/auth/auth-actions'
import { RootState } from 'stores/index'
import { useNavigate } from 'react-router-dom'
import LoginIcon from '../../../../assets/login.png'
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha'
import { SecurityOutlined } from '@mui/icons-material'
import { Snackbar } from '@mui/material'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'

interface State {
  nickname: string
  password: string
  code: string
  showPassword: boolean
}

export default function InputAdornments(): JSX.Element {
  const [values, setValues] = React.useState<State>({
    nickname: '',
    password: '',
    showPassword: false,
    code: '',
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // const { error } = useSelector((state: RootState) => state.auth)
  const { notification, isLoading } = useSelector((state: RootState) => state.ui)
  const { token } = useSelector((state: RootState) => state.auth)

  React.useEffect(() => {
    if (token) {
      navigate('/app', { replace: true })
    }
  }, [navigate, token])

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleSubmit = async (event: React.FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    let user_captcha_value = values.code

    if (validateCaptcha(user_captcha_value) == true) {
      dispatch(login(values.nickname, values.password))
    } else {
      setAlertOpen(true)
    }
  }

  React.useEffect(() => {
    loadCaptchaEnginge(5, '#fff', '#000', 'numbers')
  }, [])

  const [alertOpen, setAlertOpen] = React.useState(false)
  return (
    <Box sx={{ height: '100%', backgroundColor: '#535250' }}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={alertOpen}
        autoHideDuration={2000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert severity="error">Captcha donot match</Alert>
      </Snackbar>
      {/* {token && <Navigate to="/app" replace={true} />} */}
      <PageTitle pageTitle="Login | Galaxy GamesGroup" />
      <ScaleContainer childId="login-box" key="login" parentId="login-container">
        <Box
          id="login-box"
          sx={{
            height: 'calc(100vh)',
            minWidth: '600px',
            bgcolor: '#535250',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Container maxWidth="sm">
            {notification?.status === 502 && <Alert severity="error">{notification.msg}</Alert>}

            <Box
              component="form"
              sx={{
                boxShadow: 3,
                maxWidth: 442,
                margin: 'auto',
                borderRadius: '6px',
                bgcolor: '#fffffff2',
                padding: '10px',
                '& .MuiInputBase-root': {
                  height: '36px',
                },
                '& input::placeholder': {
                  color: '#757575',
                  opacity: '1',
                  fontSize: '14px',
                },
              }}
              onSubmit={handleSubmit}
            >
              <div
                style={{
                  borderBottom: '1px solid #dcdcdc',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px',
                }}
              >
                <img src={LoginIcon} height={60} width={120} alt="Login Icon" />
              </div>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  margin: '10px 20px',
                }}
              >
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChange('nickname')}
                  value={values.nickname}
                  placeholder="Enter your username"
                  error={notification && notification.status < 500 ? true : false}
                  helperText={notification && notification.status < 500 && notification.msg}
                  fullWidth
                  required
                  sx={{
                    marginBottom: '12px',
                    fontSize: '12px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                    },
                    background: '#fff',
                    '& input': {
                      padding: '8px 0px',
                    },
                  }}
                />

                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    marginBottom: '12px',
                    fontSize: '12px',
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '4px',
                    },
                    background: '#fff',
                    '& input': {
                      padding: '8px 0px',
                    },
                  }}
                  onChange={handleChange('password')}
                  value={values.password}
                  error={notification && notification.status < 500 ? true : false}
                  type={`${values.showPassword ? 'text' : 'password'}`}
                  placeholder="Enter your password"
                  fullWidth
                  required
                />

                <Box
                  sx={{
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'center',
                    marginBottom: '12px',
                    width: '100%',
                  }}
                >
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SecurityOutlined />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      fontSize: '12px',
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '4px',
                      },
                      background: '#fff',
                      '& input': {
                        padding: '8px 0px',
                      },
                    }}
                    onChange={handleChange('code')}
                    value={values.code}
                    error={notification && notification.status < 500 ? true : false}
                    placeholder="Code"
                    fullWidth
                    required
                  />
                  <Box
                    sx={{
                      height: '36px',
                      border: '1px solid #037cb1',
                      boxShadow: 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6)',
                    }}
                  >
                    <LoadCanvasTemplateNoReload />
                  </Box>
                </Box>

                {/* <Box sx={{ display: 'flex', justifyContent: 'center', maxWidth: '100%', my: 0 }}> */}
                <LoadingButton
                  type="submit"
                  loading={isLoading?.usedFor === 'loginBtn' && isLoading.status}
                  variant="contained"
                  size="large"
                  sx={{
                    maxHeight: '34px',
                    fontSize: '15px',
                    textTransform: 'none',
                    backgroundColor: '#037cb1',
                    borderRadius: '6px',
                    '&:hover': {
                      backgroundColor: '#037cb1',
                    },
                  }}
                  fullWidth
                >
                  Login
                </LoadingButton>
              </Box>
              <Typography sx={{ marginTop: '30px', textAlign: 'right' }}>
                Version:2.0.209
              </Typography>
              {/* </Box> */}
            </Box>
          </Container>
        </Box>
      </ScaleContainer>
    </Box>
  )
}
