import React from 'react'
import { Typography, Box } from '@mui/material'

interface TextInterface {
  listTitle?: any
  listDesc?: string
  lg?:any
  md?:any
  sm?:any
  xs?:any
  mb?:any
  flex?: any
}

const StageList = ({ listTitle, listDesc, md, sm, xs, lg,mb, flex }: TextInterface) => {
  return (
    <Box mb={mb} sx = {{display: 'flex', flexDirection: 'row'}}>
      <Typography sx = {{flex: { md: md, xs: xs, lg: lg, sm: sm }, fontWeight: 'bold'}}>{listTitle}</Typography>
      <Typography sx = {{flex: flex ? flex : 14}}>{listDesc}</Typography>
    </Box>
  )
}

export default StageList
