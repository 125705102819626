import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '@mui/icons-material/Android'
import DesktopMacIcon from '@mui/icons-material/DesktopMac'
import HelpCenterIcon from '@mui/icons-material/HelpCenter'
import InputIcon from '@mui/icons-material/Input'
import { isMobile, isAndroid, isIOS } from 'react-device-detect'
import { Container, Typography, Button, Box, TextField, CircularProgress } from '@mui/material'
import { APP_FILE_NAMES } from 'constants/index'
import { UdidInterface } from 'stores/udid/udid-slice'
import { createUdid } from 'stores/udid/udid-actions'
import { getGameLink } from 'stores/game/game-action'
import ModalComponent from 'app/web/components/Modal'

interface DownloadButtonProps {
  isAndroid: boolean
  fileName: string
}

const DownloadButton = () => {
  const dispatch = useDispatch()
  const [currentUdid, setCurrentUdid] = useState<UdidInterface | undefined>()
  const [isLoading, setIsLoading] = useState(true)

  const handleOpenModal = () => setCurrentUdid({ udid: '', securityCode: '', macId: '' })
  const handleCloseModal = () => setCurrentUdid(undefined)
  const { gameLink } = useSelector((state: RootState) => state.gameList)

  // useEffect(() => {
  //   dispatch(getGameLink(false))
  // }, [dispatch])

  useEffect(() => {
    const fetchGameLink = async () => {
      await dispatch(getGameLink(false))
      setIsLoading(false)
    }
    fetchGameLink()
  }, [dispatch])

  const handleAddUdid = (udid: UdidInterface) => () => {
    if (udid.udid && udid.securityCode) {
      dispatch(createUdid(udid.udid, udid.securityCode, udid.macId))
      handleCloseModal()
    }
  }

  const DownloadButton = ({ isAndroid }: DownloadButtonProps) => {
    const handleDownloadClick = () => {
      if (isAndroid) {
        window.location.replace(gameLink?.apkLink as string)
      } else {
        window.location.replace(gameLink?.iosLink as string)
      }
    }

    return (
      <Box sx={{ width: '90%' }}>
        <Button
          variant="contained"
          onClick={handleDownloadClick}
          startIcon={isAndroid ? <AndroidIcon /> : <AppleIcon />}
          sx={{
            // cursor: 'pointer',
            cursor: isLoading ? 'not-allowed' : 'pointer',

            p: '14px 20px',
            fontSize: '17px',
            borderRadius: '10px',
            background: 'radial-gradient(at center center, #F8E18B 46%, #D7B250 100%)',
            color: '#000',
            fontWeight: 600,
            textTransform: 'none',
            width: '100%',
            // width: '260px',
            '&:hover': {
              background: 'radial-gradient(at center center, #F8E18B 46%, #D7B250 100%)',
              color: '#000',
              transform: 'scale(1.05)',
              transition: 'all 0.3s ease-in-out',
            },
          }}
          disabled={isLoading}
        >
          {/* Download for {isAndroid ? 'Android' : 'iOS'} */}
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            `Download for ${isAndroid ? 'Android' : 'iOS'}`
          )}
        </Button>
      </Box>
    )
  }

  const PlayButton = () => {
    const [isPlayLoading, setIsPlayLoading] = useState(false)

    const handlePlayClick = () => {
      setIsPlayLoading(true)

      window.location.replace(gameLink?.webLink as string)
    }

    useEffect(() => {
      if (!isPlayLoading) {
        // Reset the loading state when isLoading is set to false
        setIsPlayLoading(false)
      }
      return () => {
        // Reset the loading state when the component is unmounted
        setIsPlayLoading(false)
      }
    }, [isPlayLoading])

    return (
      <Box sx={{ width: '90%' }}>
        <Button
          variant="contained"
          onClick={handlePlayClick}
          startIcon={<DesktopMacIcon />}
          sx={{
            cursor: isLoading ? 'not-allowed' : 'pointer',
            borderRadius: '10px',
            background: 'radial-gradient(at center center, #2170df 46%, #0055cd 100%)',
            color: '#fff',
            textTransform: 'none',
            p: '14px 20px',
            fontSize: '17px',
            width: '100%',
            // width: '260px',
            '&:hover': {
              background: 'radial-gradient(at center center, #2170df 46%, #0055cd 100%)',
              transform: 'scale(1.05)',
            },
          }}
          disabled={isPlayLoading}
        >
          {isPlayLoading ? <CircularProgress size={24} color="inherit" /> : 'Play on Web'}
        </Button>
      </Box>
    )
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        position: 'relative',
        height: '100vh',
        p: '0px !important',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url('/static/bg3.jpg')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          filter: 'blur(3px)',
          position: 'absolute',
          inset: '0px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          margin: 'auto',
          width: '100%',
          borderRadius: '4px',
        }}
      >
        {/* <Typography
          variant="h3"
          sx={{ textAlign: 'justify', fontWeight: 'bold', fontSize: '21px', marginBottom: ' 9px' }}
        >
          Download
        </Typography> */}

        {/* <Typography variant="h3" sx={{ textAlign: 'justify', color: '#8d8484', fontSize: '17px' }}>
          Download the app to play on your mobile device or play on web.
        </Typography> */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            maxWidth: 500,
          }}
        >
          {isAndroid && <DownloadButton isAndroid={true} fileName={APP_FILE_NAMES.android} />}
          {isIOS && <DownloadButton isAndroid={false} fileName={APP_FILE_NAMES.ios} />}
          {isMobile && <PlayButton />}
          {!isMobile && (
            <>
              <DownloadButton isAndroid={true} fileName={APP_FILE_NAMES.android} />
              <DownloadButton isAndroid={false} fileName={APP_FILE_NAMES.ios} />
              <PlayButton />
            </>
          )}
        </Box>
        <Box
        // sx={{
        //   position: 'absolute',
        //   bottom: 70,
        //   '@media (min-width: 600px)': {
        //     bottom: 10,
        //   },
        // }}
        >
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', maxWidth: 500 }}>
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                maxWidth: 500,
                alignItems: 'center',
                width: '90%',
              }}
            >
              <Button
                href="https://udid.tech"
                target="_blank"
                rel="noopener noreferrer"
                variant="text"
                // size="large"
                // startIcon={<HelpCenterIcon />}
                sx={{
                  cursor: 'pointer',
                  p: '16px 6px',
                  flex: 1,
                  fontSize: '15px',
                  textTransform: 'none',
                  borderRadius: '10px',
                  backgroundColor: 'transparent',
                  textAlign: 'center',
                  border: 'none',
                  fontWeight: 600,

                  color: '#fff',
                  textDecoration: 'underline',
                  // width: '260px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#fff',
                    transform: 'scale(1.05)',
                    boxShadow: 'none',
                    border: 'none',
                    textDecoration: 'underline',
                    transition: 'all 0.3s ease-in-out',
                  },
                }}
              >
                Find your UDID
              </Button>

              <Button
                variant="contained"
                // startIcon={<InputIcon />}
                sx={{
                  cursor: 'pointer',
                  p: '16px 6px',
                  flex: 1,
                  border: 'none',
                  fontWeight: 600,
                  boxShadow: 'none',
                  textAlign: 'center',
                  fontSize: '15px',
                  textTransform: 'none',
                  textDecoration: 'underline',
                  borderRadius: '10px',
                  backgroundColor: 'transparent',
                  color: '#fff',
                  // width: '260px',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: '#fff',
                    transform: 'scale(1.05)',
                    boxShadow: 'none',
                    border: 'none',
                    textDecoration: 'underline',
                    transition: 'all 0.3s ease-in-out',
                  },
                }}
                onClick={handleOpenModal}
              >
                Add UDID Directly
              </Button>
            </Box>
          </Box>
          <Typography
            variant="h3"
            sx={{
              textAlign: 'center',
              color: '#fff',
              fontSize: '13px',
              p: '0px 16px',
              position: 'relative',
              maxWidth: 500,
            }}
          >
            For account security, your device need to be binded to your respective account and
            phone.
          </Typography>
        </Box>
      </Box>

      <ModalComponent
        title="Add UDID"
        handleClose={handleCloseModal}
        isOpen={!!currentUdid}
        saveButtonTitle="Add"
        handleSave={handleAddUdid(currentUdid!)}
      >
        <TextField
          label="UDID"
          variant="outlined"
          fullWidth
          sx={{
            my: 2,
            '& .MuiInputBase-root': {
              borderRadius: '6px',
            },
          }}
          value={currentUdid?.udid || ''}
          onChange={(e) => setCurrentUdid({ ...currentUdid, udid: e.target.value })}
        />
        <TextField
          label="Security Code"
          variant="outlined"
          fullWidth
          sx={{
            mb: 2,
            '& .MuiInputBase-root': {
              borderRadius: '6px',
            },
          }}
          value={currentUdid?.securityCode || ''}
          onChange={(e) => setCurrentUdid({ ...currentUdid, securityCode: e.target.value })}
        />
        <TextField
          label="Mac Id"
          variant="outlined"
          fullWidth
          sx={{
            mb: 2,
            '& .MuiInputBase-root': {
              borderRadius: '6px',
            },
          }}
          value={currentUdid?.macId || ''}
          onChange={(e) => setCurrentUdid({ ...currentUdid, macId: e.target.value })}
        />
      </ModalComponent>
    </Container>
  )
}

export default DownloadButton
