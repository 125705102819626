import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { ThemeProvider } from '@mui/material'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { theme, darkTheme } from 'app/dashboard/theme'
import GlobalStyles from 'app/dashboard/components/GlobalStyles'
import MainLayout from 'app/web/layout'
import DashboardLayout from 'app/dashboard/layout/DashboardLayout'
import LoginPage from 'app/dashboard/pages/auth/LoginPage'
import UserPage from 'app/dashboard/pages/users/index'
import TransactionPage from 'app/dashboard/pages/transactions/index'
import GamePage from 'app/dashboard/pages/games/index'
import AddNew from 'app/dashboard/pages/games/AddGame/index'
import DashboardPage from 'app/dashboard/pages/dashboard/index'
import CreateAnnouncement from 'app/dashboard/pages/anouncement/add/index'
import AnnouncementPage from 'app/dashboard/pages/anouncement/index'
import ManageGame from 'app/dashboard/pages/games/ManageGame/index'
import ManageCategory from 'app/dashboard/pages/category/index'
import ManageDistributor from 'app/dashboard/pages/distributor/index'
import ManageUDID from 'app/dashboard/pages/udid/index'
import AddCategory from 'app/dashboard/pages/category/AddNew'
import NotFoundPage from 'app/dashboard/pages/errors/NotFoundPage'
import JpPage from 'app/dashboard/pages/jp/index'
import AdminPage from 'app/dashboard/pages/admin/index'
import ReportPage from 'app/dashboard/pages/reports/index'
import ContactPage from 'app/dashboard/pages/contact/index'
import JpSettingsPage from 'app/dashboard/pages/jp/JpSettings'
import BurntDownRecords from 'app/dashboard/pages/burntdown/index'
import Settings from 'app/dashboard/pages/settings/index'
import CreateAccount from 'app/dashboard/components/NewUser'
import RequireAdminAccess from 'app/dashboard/components/Auth/RequireAdminAccess'
import Stores from 'app/web/pages/StoresAndDestribution'
import Product from 'app/web/pages/Product'
import Games from 'app/web/pages/Games'
import Contact from 'app/web/pages/ContactUs'
import Home from 'app/web/pages/Home/Home'
import GameDetail from 'app/web/pages/Games/GameDetail'
import Play from 'app/web/pages/Play'
import Download from 'app/web/pages/Play/Download'
import Join from 'app/web/pages/Play/Join'
import ProtectedAccess from 'app/dashboard/components/Auth/ProtectedAccess'
import ThankyouPage from 'app/web/pages/ContactUs/ThankyouPage'
import 'App.scss'
import './indexapp.css'
import 'simplebar/dist/simplebar.min.css'
import PrivacyPolicy from 'app/web/pages/PrivacyPolicy'
import RequireSubAdminAccess from 'app/dashboard/components/Auth/RequireSubAdminAccess'
import Notification from 'app/dashboard/components/Notifications'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'
import TestDownload from 'app/web/pages/Play/TestDownload'
import { LocalizationProvider } from '@mui/x-date-pickers'

const App = (): JSX.Element => {
  const { isDarkMode, notification } = useSelector((state: RootState) => state.ui)
  // const ENV_FILE_NAME = process.env.REACT_APP_ENV_FILE_NAME

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <ThemeProvider theme={isDarkMode ? darkTheme : theme}>
        {notification && notification.status < 500 && notification.title === 'error' && (
          <Notification notiType="error" msg={notification.msg} />
        )}
        {notification && notification.status !== 404 && notification.title === 'success' && (
          <Notification notiType="success" msg={notification.msg} />
        )}
        <GlobalStyles />
        {/* {ENV_FILE_NAME !== 'PRODUCTION' && ENV_FILE_NAME !== 'LOCAL' && (
        <div className="env">{ENV_FILE_NAME}</div>
      )} */}
        <Router>
          <Routes>
            <Route path="/" element={<MainLayout />}>
              <Route path="/" element={<Home />}></Route>
              <Route path="/games" element={<Games />} />
              <Route path="/games/:gameId" element={<GameDetail />} />
              <Route path="/stores" element={<Stores />} />
              <Route path="/product" element={<Product />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/play" element={<Play />} />
              <Route path="/download" element={<Download />} />
              <Route path="/download/test" element={<TestDownload />} />
              <Route path="/join" element={<Join />} />
              <Route path="/thankyou" element={<ThankyouPage />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Route>

            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/app"
              element={
                <RequireAdminAccess>
                  <DashboardLayout />
                </RequireAdminAccess>
              }
            >
              <Route path="/app" element={<Navigate replace to="/app/users" />} />

              {/* {isLocalHost() ? <Route path="dashboard" element={<DashboardPage />} /> : null} */}
              <Route path="dashboard" element={<DashboardPage />} />

              <Route path="users" element={<UserPage />} />
              <Route path="transactions" element={<TransactionPage />} />
              <Route path="games" element={<GamePage />} />
              <Route path="announcement" element={<RequireSubAdminAccess />}>
                <Route path="" element={<AnnouncementPage />} />
                <Route path="edit/:id" element={<CreateAnnouncement actionType="edit" />} />
                <Route path="create" element={<CreateAnnouncement />} />
              </Route>
              <Route path="manage" element={<ProtectedAccess />}>
                <Route path="games" element={<ManageGame />} />
                <Route path="category" element={<ManageCategory />} />
                <Route path="contact-message" element={<ContactPage />} />
                <Route path="distributors" element={<ManageDistributor />} />
                <Route path="udid" element={<ManageUDID />} />
              </Route>
              <Route path="add" element={<ProtectedAccess />}>
                <Route path="game" element={<AddNew />} />
                <Route path="category" element={<AddCategory />} />
              </Route>
              <Route path="edit/category/:id" element={<AddCategory actionType="edit" />} />
              {/* <Route
              path="edit/announcement/:id"
              element={<CreateAnnouncement actionType="edit" />}
            /> */}
              <Route path="edit/game/:id" element={<AddNew actionType="edit" />} />
              <Route path="jp-records" element={<JpPage />} />
              <Route path="admin-structure" element={<AdminPage />} />
              <Route path="reports" element={<ReportPage />} />
              <Route path="burnt-down-records" element={<BurntDownRecords />} />
              <Route path="jp-settings" element={<JpSettingsPage />} />
              <Route path="settings" element={<Settings />} />
              <Route path="create/account" element={<CreateAccount />} />
              {/* <Route path="create/announcement" element={<CreateAnnouncement />} /> */}
            </Route>
            <Route path="/404" element={<NotFoundPage />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default App
