import React, { useEffect, useRef } from 'react'
import { Card, CardActions, CardContent, Typography, Button, Grid } from '@mui/material'
import { Link } from 'react-router-dom'
import gsap from 'gsap'

interface CardInterface {
  stage: string
  title: string
  subTitle?: string
  description?: string
  buttonText?: string
  icon?: any
}
const ProcessCard = ({ stage, title, buttonText, description, icon }: CardInterface) => {
  const processRef = useRef(null)

  useEffect(() => {
    console.log('wrapperRef', processRef.current)
    gsap.fromTo(
      processRef.current,
      { opacity: 0 },
      {
        duration: 3,
        opacity: 1,
        // ease: 'elastic'
      }
    )
  }, [])
  return (
    <Grid item sm={8} xs={12} md={4} lg={3} m={1} ref={processRef}>
      <Card
        sx={{
          minWidth: 275,
          minHeight: '220px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '25px',
          bgcolor: '#1f1b24',
          border: '1px solid #DCDCDC',
          borderRadius: '20px',
        }}
      >
        <CardContent>
          <Typography
            textAlign="center"
            variant="h3"
            gutterBottom
            sx={{ fontWeight: '700' }}
            color="#DCDCDC"
            // mb={2}
          >
            {stage}
          </Typography>
          <Typography
            variant="h4"
            textAlign="center"
            sx={{ fontWeight: 'semi-bold' }}
            color="#DCDCDC"
          >
            {title}
          </Typography>
        </CardContent>
        {description && (
          <Typography component="div" textAlign="center" sx={{ fontWeight: '500' }} color="#DCDCDC">
            {description}
          </Typography>
        )}
        {icon && icon}
        {buttonText && (
          <CardActions>
            <Button
              size="small"
              variant="outlined"
              component={Link}
              to="/downlaod"
              sx={{
                color: '#DCDCDC',
                border: '1px solid #DCDCDC',
                '&:hover': { border: '1px solid #DCDCDC' },
              }}
            >
              {buttonText}
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  )
}

export default ProcessCard
