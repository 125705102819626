import { authActions, ILoginAction } from './auth-slice'
import { uiActions } from 'stores/ui/ui-slice'
import api from 'config/apiConfig'
import moment from 'moment'
import { Action, Dispatch } from 'redux'
import { AxiosError } from 'axios'
import { handleErrorResponse } from 'app/dashboard/utils/errorHandler'

export interface ServerError {
  error: string
}

export const login =
  (nickname: string, password: string) =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      dispatch(uiActions.setLoading({ status: true, usedFor: 'loginBtn' }))
      const { data } = await api.post<ILoginAction>('/admin/login', {
        nickname,
        password,
      })
      const { token, userId, role, tz } = data
      localStorage.setItem('auth', JSON.stringify({ token, userId, role, tz }))
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`
      api.defaults.headers.common['tz'] = tz || moment.tz.guess()
      dispatch(authActions.login({ token, userId, role, tz }))
      dispatch(uiActions.hideNotification())
    } catch (error) {
      if (error) {
        const serverError = error as AxiosError<ServerError>
        handleErrorResponse(serverError, dispatch)
      }
    } finally {
      dispatch(uiActions.setLoading({ status: false, usedFor: 'loginBtn' }))
    }
  }

export const logout =
  () =>
  async (dispatch: Dispatch<Action>): Promise<void> => {
    try {
      await api.post('/users/logout')
      localStorage.removeItem('auth')
      dispatch(authActions.logout())
      window.location.href = '/login'
    } catch (error) {
      window.alert('Unauthorized action! Clear localstorage.')
    }
  }
