import React from 'react'
import { Container, Typography, Button, Box } from '@mui/material'
import { Link } from 'react-router-dom'
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
const DownloadApp = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        mt: 10,
        backgroundImage: `url('/static/images/games/bg.jpg')`,
        backgroundSize: 'contain',
        padding: '30px',
      }}
    >
      <Typography
        sx={{ textTransform: 'uppercase', textShadow: '2px 2px rgba(108, 122, 137, 1)' }}
        textAlign="center"
        variant="h1"
        fontWeight="bolder"
        color="white"
      >
        Please. Download The Game Now
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
        <Button variant="contained" startIcon = {<AndroidIcon />} sx={{ mr: 2, padding: '12px',  }}>
          Android
        </Button>
        <Button variant="contained" startIcon = {<AppleIcon />} sx={{ padding: '12px', }}>
          <a
            href="itms-services://?action=download-manifest&url=https://api.thegigabytegroup.com/app/ios/manifest.plist"
            id="text"
            style={{ color: 'white' }}
          >
            {' '}
            IoS
          </a>
        </Button>
      </Box>
      <Link to="/help">
        <Typography
          sx={{ textShadow: '2px 2px rgba(8, 14, 44, 1)' }}
          textAlign="center"
          variant="h3"
          fontWeight="bolder"
          color="white"
          mt={2}
        >
          Download Guide
        </Typography>
      </Link>
    </Container>
  )
}

export default DownloadApp
