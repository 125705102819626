//@ts-nocheck
import React, { useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography,
  ListItem,
  Stack,
  Button,
} from '@mui/material'
import { Settings as SettingsIcon, Users as UsersIcon } from 'react-feather'
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined'
import ViewTimelineIcon from '@mui/icons-material/ViewTimeline'
import SportsEsportsOutlinedIcon from '@mui/icons-material/SportsEsportsOutlined'
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined'
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import CategoryIcon from '@mui/icons-material/Category'
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined'
import InputIcon from '@mui/icons-material/Input'
import GamesIcon from '@mui/icons-material/Games'
import EmailIcon from '@mui/icons-material/Email'
import StoreIcon from '@mui/icons-material/Store'
import WhatshotIcon from '@mui/icons-material/Whatshot'
import NavItem from './NavItem'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { logout } from 'stores/auth/auth-actions'
import { UserRoleEnum } from 'stores/auth/auth-slice'
import AddBalance from 'app/dashboard/components/AddBalance'
import { NONAME } from 'dns'
// import { isLocalHost } from 'utils'
import LogoutIcon from '@mui/icons-material/Logout'
interface IUser {
  avatar: string
  name: string
  balance: number
}

const user: IUser = {
  avatar: '/static/images/avatars/avatar_12.jpg',
  balance: 350,
  name: 'Katarina Smith',
}

interface ItemsInterface {
  href: string
  title: string
  category: object
  icon: React.ElementType
  roles: any
}

interface CategoryInterface {
  menu: string
  label: string
  items: ItemsInterface[]
  roles: any
  hide?: boolean
}

const menuItems: (ItemsInterface | CategoryInterface)[] = [
  {
    href: '/app/users',
    icon: UsersIcon,
    title: 'User Management',
    roles: [
      UserRoleEnum.MANAGER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
  },
  {
    href: '/app/transactions',
    icon: PaidOutlinedIcon,
    title: 'Transaction Records',
    roles: [
      UserRoleEnum.MANAGER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
  },
  {
    href: '/app/games',
    icon: SportsEsportsOutlinedIcon,
    title: 'Game Records',
    roles: [
      UserRoleEnum.MANAGER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
  },
  {
    href: '/app/jp-records',
    icon: MoneyOutlinedIcon,
    title: 'JP Records',
    roles: [
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
  },
  {
    href: '/app/admin-structure',
    icon: AdminPanelSettingsOutlinedIcon,
    title: 'Admin Structure',
    roles: [
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
  },
  {
    href: '/app/reports',
    icon: DonutLargeOutlinedIcon,
    roles: [
      UserRoleEnum.MANAGER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
    title: 'Reports',
  },
  {
    href: '/app/jp-settings',
    icon: SettingsIcon,
    roles: [
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
    title: 'JP Settings',
  },
  {
    href: '/app/manage/contact-message',
    roles: [UserRoleEnum.SUPERADMIN],
    icon: EmailIcon,
    title: 'Contact Messages',
  },

  // {
  // category: 'records',
  // label: 'Record',
  // roles: [
  //   UserRoleEnum.MANAGER,
  //   UserRoleEnum.ADMIN,
  //   UserRoleEnum.VENDOR,
  //   UserRoleEnum.DISTRIBUTOR,
  //   UserRoleEnum.MASTER,
  //   UserRoleEnum.ADMIN,
  //   UserRoleEnum.SUPERADMIN,
  // ],
  // items: [

  {
    href: '/app/burnt-down-records',
    icon: WhatshotIcon,
    title: 'Burnt Down Records',
    roles: [
      UserRoleEnum.MANAGER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.SUPERADMIN,
    ],
  },
  // ],
  // },

  // {
  //   category: 'managements',
  //   label: 'Manage',
  //   roles: [UserRoleEnum.SUPERADMIN],
  //   items: [
  {
    href: '/app/manage/games',
    icon: GamesIcon,
    roles: [UserRoleEnum.SUPERADMIN],
    title: 'Manage Games',
  },

  {
    href: '/app/manage/category',
    icon: CategoryIcon,
    roles: [UserRoleEnum.SUPERADMIN],
    title: 'Manage Category',
  },

  {
    href: '/app/manage/distributors',
    icon: StoreIcon,
    roles: [UserRoleEnum.SUPERADMIN],
    title: 'Manage Distributors',
  },
  {
    href: '/app/manage/udid',
    icon: VpnKeyOutlinedIcon,
    roles: [UserRoleEnum.SUPERADMIN],
    title: 'Manage UDID',
  },
  //   ],
  // },
  // {
  //   href: '/app/dashboard',
  //   icon: ViewTimelineIcon,
  //   title: 'Dashboard',
  //   // hide: !isLocalHost(),
  //   roles: [
  //     UserRoleEnum.MANAGER,
  //     UserRoleEnum.ADMIN,
  //     UserRoleEnum.VENDOR,
  //     UserRoleEnum.DISTRIBUTOR,
  //     UserRoleEnum.MASTER,
  //     UserRoleEnum.ADMIN,
  //     UserRoleEnum.SUPERADMIN,
  //   ],
  // },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings',
    // hide: !isLocalHost(),
    roles: [
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
      UserRoleEnum.MANAGER,
    ],
  },
  {
    roles: [
      UserRoleEnum.MANAGER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.VENDOR,
      UserRoleEnum.DISTRIBUTOR,
      UserRoleEnum.MASTER,
      UserRoleEnum.ADMIN,
      UserRoleEnum.SUPERADMIN,
    ],
    icon: LogoutIcon,
    title: 'Logout',
  },
]

interface NavBarProps {
  onMobileClose: () => void
  openMobile: boolean
}

const NavBar: React.FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const location = useLocation()
  const { userInfo } = useSelector((state: RootState) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const handleLogout = () => {
    dispatch(logout())
  }

  const content = (
    <Box
      sx={{
        backgroundColor: 'background.default',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          color: 'text.disabled',
          height: 'inherit',
        }}
      >
        {/* <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pl: 2,
            pt: 2,
            pr: 2,
          }}
        >
          <Avatar
            component={RouterLink}
            src={user.avatar}
            sx={{
              cursor: 'pointer',
              width: 64,
              height: 64,
            }}
            to="/app/users"
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 2,
            }}
          >
            <Typography
              sx={{
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'text.primary' : 'primary.light',
                fontWeight: 'bold',
                pt: 2,
              }}
              variant="h6"
            >
              {userInfo?.nickname.toUpperCase()}
            </Typography>

            <Typography color="text.disabled" variant="h6">
              {userInfo?.role}
            </Typography>

            <Typography
              color="secondary.main"
              fontWeight="fontWeightBold"
              variant="h6"
              sx={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
            >
              <PaidOutlinedIcon fontSize="small" />

              <span style={{ margin: '3.5px 0 0 4px' }}>{userInfo?.balance.toFixed(2)}</span>
            </Typography>
          </Box>
        </Box> */}

        {/* <Hidden mdUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap',
            }}
          >
            <AddBalance />
          </Box>
        </Hidden> */}

        {/* <Divider sx={{ backgroundColor: 'divider' }} /> */}

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Stack>
            {menuItems.map((menu, index) => {
              if (menu.hide) {
                return null
              }

              const isValid = menu?.roles.includes(userInfo?.role)

              if (isValid) {
                const isCategorizedMenu = !!menu?.category

                // map categorized menu
                if (isCategorizedMenu) {
                  return (
                    <React.Fragment key={menu.category + index}>
                      <Box ml={1.5} mt={1} mb={0.5} color="text.primary">
                        <Typography variant="subtitle1" color="inherit">
                          <b>{menu.label}</b>
                        </Typography>
                      </Box>

                      {menu.items.map((submenu, subindex) => {
                        const isValid = submenu?.roles.includes(userInfo?.role)

                        if (isValid) {
                          return (
                            <NavItem
                              href={submenu.href}
                              key={submenu.title}
                              title={submenu.title}
                              icon={submenu.icon}
                            />
                          )
                        }

                        return null
                      })}
                    </React.Fragment>
                  )
                }

                // map independent menu

                return (
                  <NavItem
                    key={'menu' + index}
                    href={menu.href}
                    title={menu.title}
                    icon={menu.icon}
                    onClick={menu.title === 'Logout' ? handleLogout : null}
                  />
                )
              }

              return null
            })}
          </Stack>
          {/* 
          <Hidden lgUp>
            <ListItem
              disableGutters
              sx={{
                display: 'flex',
                py: 0,
              }}
            >
              <Button
                sx={{
                  color: 'text.secondary',
                  fontWeight: 'bold',
                  justifyContent: 'flex-start',
                  letterSpacing: 0,
                  py: 1.25,
                  textTransform: 'none',

                  '&:hover': {
                    backgroundColor: '#242A38',
                  },
                  '& svg': {
                    mr: 1,
                  },
                }}
                onClick={handleLogout}
              >
                <InputIcon fontSize="small" />

                <span>Logout</span>
              </Button>
            </ListItem>
          </Hidden> */}
        </Box>
      </Box>
    </Box>
  )

  return (
    <>
      {/* <Hidden mdUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
              backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'primary.main' : ''),
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden> */}

      {/* <Hidden mdDown> */}
      {/* <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              position: 'relative',
              width: 256,
              border: 'none',
              backgroundColor: (theme) => (theme.palette.mode === 'light' ? 'primary.light' : ''),
            },
          }}
        > */}
      <Box sx={{ width: 256, border: '2px solid #ccc', borderRadius: '6px', overflow: 'auto' }}>
        {content}
      </Box>
      {/* </Drawer> */}
      {/* </Hidden> */}
    </>
  )
}

export default NavBar
