import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import AppleIcon from '@mui/icons-material/Apple'
import AndroidIcon from '@mui/icons-material/Android'
import { isMobile, isAndroid, isIOS } from 'react-device-detect'
import { Container, Typography, Button, Box, TextField } from '@mui/material'
import { APP_FILE_NAMES } from 'constants/index'
import { UdidInterface } from 'stores/udid/udid-slice'
import { createUdid } from 'stores/udid/udid-actions'
import { getGameLink } from 'stores/game/game-action'
import ModalComponent from 'app/web/components/Modal'
import DesktopMacIcon from '@mui/icons-material/DesktopMac';



interface DownloadButtonProps {
  isAndroid: boolean
  fileName: string
}

const TestDownload = () => {
  const dispatch = useDispatch()
  const [currentUdid, setCurrentUdid] = useState<UdidInterface | undefined>()
  const handleOpenModal = () => setCurrentUdid({ udid: '', securityCode: '', macId: '' })
  const handleCloseModal = () => setCurrentUdid(undefined)
  const { gameLink } = useSelector((state: RootState) => state.gameList)

  useEffect(() => {
    dispatch(getGameLink(true))
  }, [dispatch])

  const handleAddUdid = (udid: UdidInterface) => () => {
    if (udid.udid && udid.securityCode) {
      dispatch(createUdid(udid.udid, udid.securityCode, udid.macId))
      handleCloseModal()
    }
  }

  const DownloadButton = ({ isAndroid}: DownloadButtonProps) => {
    const handleDownloadClick = () => {
      if (isAndroid) {
        window.location.replace(gameLink?.apkLink as string)
      } else {
        window.location.replace(gameLink?.iosLink as string)
      }
    }

    return (
      <Box>
        <Button
          variant="contained"
          onClick={handleDownloadClick}
          startIcon={isAndroid ? <AndroidIcon /> : <AppleIcon />}
          sx={{
            cursor: 'pointer',
            p: 2,
            fontSize: '20px',
            borderRadius: '10px',
            backgroundColor: 'floralwhite',
            color: 'black',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'floralwhite',
              color: 'black',
              transform: 'scale(1.05)',
              transition: 'all 0.3s ease-in-out',
            },
          }}
        >
          Download for {isAndroid ? 'Android' : 'iOS'}
        </Button>
      </Box>
    )
  }

  const PlayButton = () => {
    const handlePlayClick = () => {
      window.location.replace(gameLink?.webLink as string)
    }

    return (
      <Box>
        <Button
          variant="contained"
          onClick={handlePlayClick}
          startIcon={<DesktopMacIcon />}
          sx={{
            cursor: 'pointer',
            p: 2,
            fontSize: '20px',
            borderRadius: '10px',
            backgroundColor: 'floralwhite',
            color: 'black',
            textTransform: 'none',
            width: '260px',
            '&:hover': {
              backgroundColor: 'floralwhite',
              color: 'black',
              transform: 'scale(1.05)',
            }
          }}
        >
          Play on Web
        </Button>
      </Box>
    )
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        height: '100vh',
        backgroundImage: `url('/static/images/games/background-min.svg')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        }}
      >
        <Typography variant="h2" sx={{ color: 'gold', textTransform: 'uppercase' }}>
          Warning: This is a test download page
        </Typography>
        <Typography variant="h3" sx={{ color: 'white' }}>
          For account security, your device need to be binded to your respective account and phone.
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 5 }}>
          {isAndroid && <DownloadButton isAndroid={true} fileName={APP_FILE_NAMES.android} />}
          {isIOS && <DownloadButton isAndroid={false} fileName={APP_FILE_NAMES.ios} />}
          {isMobile && <PlayButton />}

          {!isMobile && (
            <>
              <DownloadButton isAndroid={true} fileName={APP_FILE_NAMES.android} />
              <DownloadButton isAndroid={false} fileName={APP_FILE_NAMES.ios} />
              <PlayButton />
            </>
          )}
        </Box>

        <Box sx={{ mt: 5 }}>
          <Typography variant="h3" sx={{ color: 'white' }}>
            <a
              href="https://udid.tech"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'floralwhite', fontSize: '10' }}
            >
              Click here for the steps to find out UDID.
            </a>
          </Typography>
        </Box>

        <Box sx={{ mt: 5 }}>
          <Button
            variant="contained"
            sx={{
              cursor: 'pointer',
              p: 2,
              fontSize: '20px',
              fontWeight: 'bold',
              borderRadius: '10px',
              backgroundColor: 'floralwhite',
              color: 'black',

              '&:hover': {
                backgroundColor: 'floralwhite',
                color: 'black',
                transform: 'scale(1.05)',
                transition: 'all 0.3s ease-in-out',
              },
            }}
            onClick={handleOpenModal}
          >
            Add UDID directly
          </Button>
        </Box>
      </Box>

      <ModalComponent
        title="Add UDID"
        handleClose={handleCloseModal}
        isOpen={!!currentUdid}
        saveButtonTitle="Add"
        handleSave={handleAddUdid(currentUdid!)}
      >
        <TextField
          label="UDID"
          variant="outlined"
          fullWidth
          sx={{ my: 2 }}
          value={currentUdid?.udid || ''}
          onChange={(e) => setCurrentUdid({ ...currentUdid, udid: e.target.value })}
        />
        <TextField
          label="Security Code"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          value={currentUdid?.securityCode || ''}
          onChange={(e) => setCurrentUdid({ ...currentUdid, securityCode: e.target.value })}
        />
        <TextField
          label="Mac Id"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          value={currentUdid?.macId || ''}
          onChange={(e) => setCurrentUdid({ ...currentUdid, macId: e.target.value })}
        />
      </ModalComponent>
    </Container>
  )
}

export default TestDownload
