import React from 'react'
import { Box } from '@mui/material'

interface FormProps {
    children: JSX.Element
  }

const Form: React.FC<FormProps> = ({ children }) => {
  return (
    <Box
      component="form"
      sx={{
        backgroundColor: 'background.default',
        maxWidth: '800px',
        boxShadow: 10,
        mx: { md: 'auto', xs: 2 },
        my: 2,
        
      }}
    >
      {children}
    </Box>
  )
}

export default Form
