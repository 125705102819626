import React from 'react'
import { Container, Typography } from '@mui/material'

const ThankyouPage = () => {
  return (
    <Container sx={{ mt: '90px', bgcolor: '#000', p: '40px' }} maxWidth="lg">
      <Typography variant="h1" color="#fff" textAlign="center" mb={3}>
        Thank You For the Submission!
      </Typography>

      <Typography variant="h5" color="#fff" textAlign="center" mb={2}>
        Message Us Via Telegram
      </Typography>

      <a href="https://telegram.org/">
        <Typography variant="h3" color="#fff" textAlign="center">
          Click Here to Contact
        </Typography>
      </a>
    </Container>
  )
}

export default ThankyouPage
