import React, { useEffect, useRef, useState, FormEvent } from 'react'
import { Container, Typography, Box, Grid, TextField, Stack, List } from '@mui/material'
import ProcessCard from 'app/web/components/ProcessCard'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import gsap from 'gsap'
import Form from 'app/web/components/Form'
import DownloadButtons from 'app/web/components/DownloadButtons'
import StageList from 'app/web/components/StageList'
import LoadingButton from '@mui/lab/LoadingButton'
import { RootState } from 'stores'
import { useDispatch, useSelector } from 'react-redux'
import { createContact } from 'stores/contact/contact-actions'

const regEmail = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/

const Join = () => {
  const iconRef = useRef(null)
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: RootState) => state.ui)

  const [formData, setFormData] = useState<{
    firstName: string
    lastName: string
    email: string
    phone: string
    address: string
    message: string
  }>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    message: '',
  })

  const [errors, setErrors] = useState({
    emailError: false,
    phoneError: false,
    addressError: false,
    messageError: false,
    emailErrorText: '',
    phoneErrorText: '',
    addressErrorText: '',
    messageErrorText: '',
  })

  useEffect(() => {
    const tween = gsap.fromTo(
      iconRef.current,
      { y: -10 },
      {
        duration: 1,
        y: 8,
        // ease: 'elastic'
      }
    )
    tween.repeat(-1)
  }, [])

  const validate = () => {
    const temp = {
      email: '',
      phone: '',
      address: '',
      message: '',
    }
    temp.email = regEmail.test(formData.email) ? '' : 'Email is not Valid'
    temp.phone = formData.phone.length >= 10 ? '' : 'Invalid Phone Number'
    temp.address = formData.address ? '' : 'This Field is required'
    temp.message = formData.message ? '' : 'This Field is required'

    setErrors({
      emailError: temp.email ? true : false,
      phoneError: temp.phone ? true : false,
      addressError: temp.address ? true : false,
      messageError: temp.message ? true : false,
      emailErrorText: temp.email,
      phoneErrorText: temp.phone,
      addressErrorText: temp.address,
      messageErrorText: temp.message,
    })

    return Object.values(temp).every((value) => value === '')
  }

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    if (validate()) {
      dispatch(createContact({ ...formData }))
    }
  }
  return (
    <>
      <Container sx={{ marginTop: '90px' }}>
        <Typography variant="h1" textAlign="center" mb={2}>
          Join The Galaxy GamesGroup
        </Typography>
        <Grid container justifyContent="center" mt={3} mb={10}>
          <ProcessCard
            stage="Stage 1"
            title="Complete The player Registeration Form"
            icon={<FileDownloadIcon fontSize="large" color="success" ref={iconRef} />}
          />
          <ProcessCard stage="Stage 2" title="Download App Now" buttonText="Go To Download" />
          <ProcessCard
            stage="Stage 3"
            title="You're Almost There"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
          <ProcessCard stage="Stage 4" title="Purchase The First Game Credits" />
          <ProcessCard stage="Stage 5" title="Let The Game Begin And Enjoy" />
          <ProcessCard
            stage="Stage 6"
            title="Stay Connected"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
        </Grid>
        <Form>
          <Box
            component="form"
            sx={{
              mx: 'auto',
              mt: { xs: 3, md: 0 },
              mb: 3,
              p: 3,
              pl: { md: 5, xs: 3, lg: 8, sm: 3 },
              pr: { md: 5, xs: 3, lg: 8, sm: 3 },
            }}
          >
            <Stack spacing={3}>
              <Stack direction="row" justifyContent="space-between" spacing={1}>
                <TextField
                  label="First Name"
                  value={formData.firstName}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
                />
                <TextField
                  label="Last Name"
                  value={formData.lastName}
                  variant="outlined"
                  size="small"
                  fullWidth
                  onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
                />
              </Stack>
              <TextField
                label="Email"
                value={formData.email}
                variant="outlined"
                size="small"
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                required
                error={errors.emailError}
                helperText={errors.emailErrorText}
              />
              <TextField
                label="Contact Number"
                value={formData.phone}
                variant="outlined"
                size="small"
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                required
                error={errors.phoneError}
                helperText={errors.phoneErrorText}
                inputProps={{ maxLength: 10 }}
              />
              <TextField
                label="Address"
                value={formData.address}
                variant="outlined"
                size="small"
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                required
                error={errors.addressError}
                helperText={errors.addressErrorText}
              />

              <TextField
                multiline
                maxRows={4}
                label="Leave a Message"
                value={formData.message}
                variant="outlined"
                size="small"
                onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                required
                error={errors.messageError}
                helperText={errors.messageErrorText}
              />
            </Stack>
            <Box sx={{ display: 'flex', justifyContent: 'left', mt: 3 }}>
              <LoadingButton
                loading={isLoading?.usedFor === 'contact' && isLoading.status}
                type="submit"
                variant="contained"
                onClick={handleSubmit}
              >
                Send Message
              </LoadingButton>
            </Box>
          </Box>
        </Form>
        <Typography textAlign="center" variant="h2" sx={{ fontWeight: 'bold', mt: 10 }}>
          How To Download The App?
        </Typography>
        <DownloadButtons />
        <Typography textAlign="left" variant="h4" sx={{ fontWeight: 'bold', mt: 10, mb: 2 }}>
          Android
        </Typography>
        <List component={Stack}>
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 1"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 2"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 3"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 4"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
        </List>
        <Typography textAlign="left" variant="h4" sx={{ fontWeight: 'bold', mt: 5, mb: 2 }}>
          Apple - IoS
        </Typography>
        <List component={Stack}>
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 1"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 2"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 3"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
          <StageList
            md={2}
            sm={2}
            xs={3}
            lg={1}
            listTitle="Stage 4"
            listDesc="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s when an unknown printer took a galley of type and scrambled it to make a"
          />
        </List>
      </Container>
    </>
  )
}

export default Join
