import { useState } from 'react'
import {
  Box,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { tableCellClasses } from '@mui/material/TableCell'
import moment from 'moment'
import { RootState } from 'stores'
import { useSelector } from 'react-redux'
import { IGameRecord } from 'stores/game/game-record-slice'
import TablePagination from 'app/web/components/TablePagination'
import ScaleContainer from 'app/dashboard/components/ScaleContainer'
import { Visibility } from '@mui/icons-material'
import GameDetailsModal from './GameDetailsModal'

const tableCellOptions: string[] = [
  'Account',
  'Game Title',
  'Played',
  'Before Balance',
  'After Balance',
  'Store',
  'Duration',
  'Last Played',
  'Won',
  'Total Profit',
]

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    fontSize: 16,
    padding: '0px 5px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    fontWeight: 600,
  },
}))

interface IGameDetails {
  games: IGameRecord[]
  handlePageChange?: (event: React.ChangeEvent<HTMLInputElement>, page: number) => void
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void
  paginationInfo?: any
}

const GameDetails: React.FC<IGameDetails> = ({
  games,
  paginationInfo,
  handlePageChange,
  handleChangeRowsPerPage,
  ...rest
}) => {
  const { isLoading } = useSelector((state: RootState) => state.ui)
  const [hasRender, setHasRender] = useState(false)

  return (
    <Card {...rest} sx={{ borderRadius: '0px', boxShadow: 'none', padding: '0px' }}>
      <ScaleContainer
        key={`${isLoading && (isLoading.usedFor === 'global' || 'game') ? 'update' : ''}-${
          games.length
        }`}
        forceRefresh={hasRender}
        parentId="game-details-table-container"
        childId="game-details-table"
        sx={{
          '& table': {
            '& th, td': {
              fontSize: 14,
            },
          },
        }}
      >
        <Table id="game-details-table" sx={{ borderRadius: 0, border: '1px solid #ccc' }}>
          <TableHead>
            <TableRow
              sx={{
                height: '31px',
                borderBottom: '1px solid #c7c7c7 !important',
              }}
            >
              {tableCellOptions.map((item, index) => (
                <StyledTableCell
                  sx={{
                    padding: 0,
                    backgroundColor: '#3398cc !important',
                    border: '1px solid #c7c7c7',
                  }}
                  key={index}
                >
                  {item}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>

          {isLoading?.status && (isLoading.usedFor === 'global' || 'game') ? (
            <TableBody>
              <TableRow
                sx={{
                  height: 'fit-content',

                  '& td': {
                    position: 'relative',
                    padding: '6px 0px',
                    color: '#000',
                    cursor: 'pointer',
                    border: '1px solid #c7c7c7',
                  },
                  '& td p': {
                    color: '#000',
                  },
                  '&:nth-of-type(even)': {
                    bgcolor: 'background.default',
                  },
                  '&:nth-of-type(odd)': {
                    bgcolor: 'background.paper',
                  },
                }}
              >
                {tableCellOptions.map((item) => (
                  <TableCell key={item}>
                    {Array.from(new Array(5)).map((_, index) => (
                      <Skeleton key={index} height={72} animation="wave" />
                    ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {games.map((game) => (
                <Row
                  data={game}
                  key={game.sessionId}
                  toggleForceRefresh={() => setHasRender(!hasRender)}
                />
              ))}
            </TableBody>
          )}
        </Table>
      </ScaleContainer>

      {paginationInfo && handleChangeRowsPerPage && handlePageChange && (
        <TablePagination
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handlePageChange={handlePageChange}
          paginationInfo={paginationInfo}
        />
      )}
    </Card>
  )
}

const Row: React.FC<{ data: IGameRecord; toggleForceRefresh: () => void }> = ({ data }) => {
  const {
    gameName,
    playedCountTotal,
    beforeBalance,
    manager,
    createdAt,
    profitTotal,
    winsCountTotal,
    currentBalance,
    id,
    endAt,
    userNickName,
  } = data

  const [detailsOpenID, setDetailsOpenID] = useState(null as null | number)

  const duration = moment(endAt).diff(moment(createdAt))

  return (
    <>
      <TableRow
        hover
        onClick={() => {
          setDetailsOpenID(id)
        }}
        sx={{
          height: 'fit-content',
          cursor: 'pointer',
          '& td': {
            border: '1px solid #c7c7c7',
            padding: '6px 4px',
            fontSize: '13px !important',
            color: '#000',
            fontWeight: '500',
          },
        }}
      >
        <TableCell>{userNickName}</TableCell>
        <TableCell>{gameName}</TableCell>

        <TableCell>{playedCountTotal}</TableCell>

        <TableCell>{beforeBalance}</TableCell>

        <TableCell>{currentBalance}</TableCell>

        <TableCell>{manager || 'store'}</TableCell>

        <TableCell>{moment.utc(duration).format('HH:mm:ss')}</TableCell>

        <TableCell>{moment.utc(endAt).local().format('DD/MM/YYYY (HH:mm a)')}</TableCell>

        <TableCell>{winsCountTotal}</TableCell>

        <TableCell
          sx={{
            color: profitTotal < 0 ? '#d32f2f !important' : '#2e7d32 !important',
          }}
        >
          <b>{profitTotal}</b>
        </TableCell>
      </TableRow>

      {/* <TableRow sx={{ display: isOpen ? '' : ' none', background: 'rgba(0,0,0,.04)' }}>
        <TableCell colSpan={10}>
          <Box sx={{ marginBottom: '20px', padding: '0 24px' }}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    height: 0,
                    '&:nth-of-type(even)': {
                      bgcolor: 'background.default',
                    },
                    '&:nth-of-type(odd)': {
                      bgcolor: 'background.paper',
                    },
                  }}
                >
                  <TableCell sx={{ fontWeight: 'bolder' }}>Wins Count</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Played Count</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Amount Won</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Bet Amount</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Status</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Before Balance</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Current Balance</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Last Played</TableCell>
                  <TableCell sx={{ fontWeight: 'bolder' }}>Profit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isOpen &&
                  insideElements.map((gameElement, index) => {
                    const {
                      winsCount,
                      playedCount,
                      amountWon,
                      betAmount,
                      status,
                      beforeBalance,
                      currentBalance,
                      createdAt,
                      profit,
                    } = gameElement
                    return (
                      <TableRow
                        sx={{
                          height: 0,
                          '&:nth-of-type(even)': {
                            bgcolor: 'background.default',
                          },
                          '&:nth-of-type(odd)': {
                            bgcolor: 'background.paper',
                          },
                        }}
                        key={index}
                      >
                        <TableCell>{winsCount}</TableCell>
                        <TableCell>{playedCount}</TableCell>
                        <TableCell>{amountWon}</TableCell>
                        <TableCell>{betAmount}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{beforeBalance}</TableCell>
                        <TableCell>{currentBalance}</TableCell>
                        <TableCell>
                          {moment.utc(createdAt).local().format('DD/MM/YYYY (HH:mm a)')}
                        </TableCell>
                        <TableCell
                          sx={{
                            color: Math.sign(profit) === -1 ? 'error.main' : 'success.main',
                          }}
                        >
                          <b>{profit}</b>
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </Box>
        </TableCell>
      </TableRow> */}
      {detailsOpenID === id && (
        <GameDetailsModal
          id={detailsOpenID}
          isOpen={detailsOpenID === id}
          onClose={() => setDetailsOpenID(null)}
        />
      )}
    </>
  )
}

export default GameDetails
