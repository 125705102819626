import React from 'react'
import { Box, Typography } from '@mui/material'
import Interweave from 'interweave'

const GameDetailComponent = ({ description }: any) => {
  return (
    <Box>
      <Typography mb={3} textAlign={'center'}>
        <Interweave content={description}/>
      </Typography>
    </Box>
  )
}

export default GameDetailComponent
