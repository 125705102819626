import React, { useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import './index.css'
import { Link } from 'react-router-dom'
import { Box, Container, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { getSimilarGames } from 'stores/game/game-action'
import { RootState } from 'stores'
import { baseURL } from 'config/apiConfig'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30,
  },
}

const GameSlider = ({ gameId, category }: any) => {
  const dispatch = useDispatch()
  const { similarGames } = useSelector((state: RootState) => state.gameList)

  useEffect(() => {
    dispatch(getSimilarGames(category, gameId))
  }, [category, dispatch, gameId])

  return (
    <Container>
      {similarGames.length ? (
        <Carousel
          ssr
          partialVisbile
          itemClass="image-item"
          responsive={responsive}
          keyBoardControl={true}
          removeArrowOnDeviceType={['tablet', 'mobile']}
        >
          {similarGames.map((game) => {
            return game.images.map((image, id) => {
              return (
                <Box
                  key={id}
                  component={Link}
                  to={`/games/${game._id}}`}
                  state={{
                    title: game.title,
                    description: game.description,
                    gameId: game._id,
                    category: game.category,
                  }}
                >
                  <img
                    key={id}
                    style={{ width: '100%', height: '100%' }}
                    src={`${baseURL}/images/${image}`}
                    alt=""
                  />
                </Box>
              )
            })
          })}
        </Carousel>
      ) : (
        <Typography>No Similar Games Found</Typography>
      )}
    </Container>
  )
}

export default GameSlider
