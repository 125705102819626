import React from 'react'
import { Container, Box, Button, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Play = () => {
  return (
    <Container sx={{ mt: '160px', mb: '90px' }}>
      <Typography variant="h2" textAlign="center" mb={3}>
        Want to be a part of The Galaxy GamesGroup?
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Link to="/join">
          <Button variant="contained" sx={{ marginRight: '10px' }}>
            Join The Galaxy GamesGroup
          </Button>
        </Link>
        <Link to="/downlaod">
          <Button variant="contained">Download the App</Button>
        </Link>
      </Box>
    </Container>
  )
}

export default Play
