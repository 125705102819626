import { useNavigate } from 'react-router-dom'
import { Box, CardMedia, CircularProgress, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { PAGE_PATHS } from 'constants/index'
import Download from '../../../../assets/LoginBtnYellow.png'
import Play from '../../../../assets/redBtn.png'
import { getGameLink } from 'stores/game/game-action'
import { useEffect, useState } from 'react'
import { RootState } from 'stores'

const Home: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { gameLink } = useSelector((state: RootState) => state.gameList)
  const [isLoading, setIsLoading] = useState(true)

  const handleDownloadClick = () => {
    navigate(PAGE_PATHS.download)
  }

  // useEffect(() => {
  //   dispatch(getGameLink(false))
  //   .then(() => setIsLoading(false))
  // }, [dispatch])

  useEffect(() => {
    const fetchGameLink = async () => {
      await dispatch(getGameLink(false))
      setIsLoading(false)
    }
    fetchGameLink()
  }, [dispatch])

  // const handleWebClick = () => {
  //   window.location.replace(gameLink?.webLink as string)
  // }
  const handleWebClick = () => {
    if (!isLoading && gameLink?.webLink) {
      window.location.replace(gameLink.webLink)
    }
  }

  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: '100vh',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url('/static/titleImage.png')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          zIndex: '105',
          height: '44%',
          width: '100%',
          position: 'absolute',
          top: '0px',
          margin: 'auto',
          '@media (min-width: 700px) ': {
            height: '40%',
          },
        }}
      />
      <Box
        sx={{
          backgroundImage: `url('/static/screen.png')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          zIndex: '100',
          height: '44%',
          width: '100%',
          position: 'absolute',
          bottom: '-90px',
          margin: 'auto',
          '@media (min-width: 600px) ': {
            height: '160px',
            bottom: 0,
          },
          '@media (max-height: 390px)': {
            height: '100px',
          },
        }}
      />
      <Box
        sx={{
          backgroundImage: `url('/static/background.jpg')`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          maxWidth: '650px',
          display: 'flex',
          position: 'absolute',
          inset: '0px',
          margin: 'auto',
        }}
      />
      {isLoading && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: 'rgba(255, 255, 255, 0.5)',
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '4px',
          flexDirection: 'column',
          position: 'absolute',
          inset: 0,
          zIndex: '110',
          margin: 'auto',
          bottom: '160px',
          top: 'unset',
          '@media (min-width: 600px)': {
            bottom: '160px',
            flexDirection: 'row',
            maxWidth: 650,
            padding: '0px 20px',
          },
          '@media (max-height: 390px)': {
            bottom: '100px',
          },
        }}
      >
        <Box
          sx={{
            width: '70%',
            position: 'relative',
            maxWidth: 500,
            '@media (min-width: 600px)': {
              width: 'unset',
              flex: '1',
            },
          }}
        >
          {/* <CardMedia component="img" image={Download} onClick={handleDownloadClick} sx={{ cursor: 'pointer' }} />  */}
          <Typography
            sx={{
              position: 'absolute',
              pointerEvents: 'none',
              inset: 0,
              margin: 'auto',
              width: 'fit-content',
              height: 'fit-content',
              fontSize: '16px',
              fontWeight: 600,
            }}
          >
            DOWNLOAD
          </Typography>
          <CardMedia
            component="img"
            image={Download}
            onClick={handleDownloadClick}
            sx={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
          />
        </Box>
        <Box
          sx={{
            width: '70%',
            position: 'relative',
            maxWidth: 500,
            '@media (min-width: 600px)': {
              width: 'unset',
              flex: '1',
            },
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              inset: 0,
              margin: 'auto',
              width: 'fit-content',
              height: 'fit-content',
              fontSize: '16px',
              pointerEvents: 'none',
              color: '#fff',
              fontWeight: 600,
            }}
          >
            PLAY ON WEB
          </Typography>

          {/* <CardMedia component="img" image={Play} onClick={handleWebClick} sx={{ cursor: 'pointer' }} />  */}
          <CardMedia
            component="img"
            image={Play}
            onClick={handleWebClick}
            sx={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
          />
        </Box>
      </Box>
      {/* <img
        src="/static/home-image.jpeg"
        alt="Gaming"
        width="100%"
        height="100%"
        style={{ objectFit: 'contain' }}
      /> */}

      {/* <img
        src="/static/AppDownload.png"
        alt="Gaming"
        width="100%"
        height="100%"
        style={{ objectFit: 'cover' }}
      /> */}
    </Box>
  )
}

export default Home
